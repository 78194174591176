import debounce from 'lodash.debounce';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { RouterState } from '@monash/portal-react';
import {
  isDomNodeType,
  isNumber,
  isObject,
} from '@monash/portal-frontend-common';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { getRouteFromPageIndex } from 'components/utilities/pages';
import HiddenTabsMenu from './hidden-tabs-menu/HiddenTabsMenu';
import Tab from './tab/Tab';
import c from './tabs.module.scss';
import { UpdatesContext } from 'components/providers/updates-provider/UpdatesProvider';
import { pageIds } from '../../../../constants/pages';

const DEFAULT_UNDERLINE_STYLES = { width: 0, left: 0, display: 'none' };
const SETTING_MENU_ICON_WIDTH = 24;
const MENU_GAP_WIDTH = 20;

const Tabs = ({ navMiddleRef }) => {
  const { setSelectedPageId, visiblePagesPageOrder, selectedPage, allPages } =
    useContext(PageContext);
  const { newCount: newUpdatesCount } = useContext(UpdatesContext);
  const { redirect } = useContext(RouterState);
  const [focused, setFocused] = useState(selectedPage);

  // map pages for responsive tabs
  const mappedPages = allPages
    .filter((item) => !item.hidden)
    .map((page) => {
      if (page.id === pageIds.UPDATES && isNumber(newUpdatesCount)) {
        return {
          ...page,
          newCount: newUpdatesCount,
        };
      }
      return page;
    })
    .map((item, i) => {
      return { ...item, width: 0, overflow: false, index: i };
    });
  const [tabs, setTabs] = useState(mappedPages);
  const visibleTabs = tabs?.filter((tab) => !tab.overflow);
  const hiddenTabs = tabs?.filter((tab) => tab.overflow);
  const hasHiddenTabs = hiddenTabs.length;

  // refs
  const tabsWrapperRef = useRef(null);
  const hiddenTabsMenuRef = useRef();

  // calc and apply dynamic underline styles via custom CSS vars
  useLayoutEffect(() => {
    const tabsNode = tabsWrapperRef.current;
    if (Array.isArray(allPages) && isObject(tabsNode)) {
      const underlineStyles =
        // hide underline if there is no selected page or if tab is not visible
        selectedPage === null || selectedPage >= visibleTabs.length
          ? DEFAULT_UNDERLINE_STYLES
          : {
              width: tabsNode.children[selectedPage]?.offsetWidth - 28,
              left: tabsNode.children[selectedPage]?.offsetLeft + 14,
              display: 'block',
            };

      tabsNode.style.setProperty(
        '--nav-tabs-underline-width',
        `${underlineStyles.width}px`
      );
      tabsNode.style.setProperty(
        '--nav-tabs-underline-left',
        `${underlineStyles.left}px`
      );
      tabsNode.style.setProperty(
        '--nav-tabs-underline-display',
        underlineStyles.display
      );
    }
  }, [selectedPage, allPages, visibleTabs.length, tabs]);

  // Arrow keys to navigate menu items.
  const moveFocus = (e) => {
    // exit on invalid tabsWrapperRef node, or no visible menu item is focused
    if (
      !isDomNodeType(tabsWrapperRef.current) ||
      !tabsWrapperRef.current.contains(document.activeElement)
    ) {
      return;
    }
    // find valid current focus, or exit
    const currentFocused = parseInt(
      document.activeElement.getAttribute('data-menu-index')
    );
    if (!isNumber(currentFocused)) return;

    // set focus target to previous item
    if (e.code === 'ArrowUp' || e.code === 'ArrowLeft') {
      const prevTarget =
        currentFocused === 0
          ? tabsWrapperRef.current?.children.length - 1
          : currentFocused - 1;
      setFocused(prevTarget);
      tabsWrapperRef.current?.children[prevTarget]?.children[0]?.focus();
    }
    // set focus target to next item
    else if (e.code === 'ArrowDown' || e.code === 'ArrowRight') {
      let nextTarget;
      if (currentFocused <= 0) {
        nextTarget = 1;
      } else {
        nextTarget =
          currentFocused === tabsWrapperRef.current?.children.length - 1
            ? 0
            : currentFocused + 1;
      }
      setFocused(nextTarget);
      tabsWrapperRef.current?.children[nextTarget]?.children[0]?.focus();
    }
    // select target page
    else if (e.code === 'Enter') {
      e.preventDefault();
      setSelectedPageId(visiblePagesPageOrder?.[focused]);
    }
  };

  // update tabs for responsive behaviours
  const updateTabs = () => {
    const navMiddleWidth = navMiddleRef.current?.getBoundingClientRect().width;
    const hiddenTabsMenuWidth = hiddenTabsMenuRef.current
      ? hiddenTabsMenuRef.current?.getBoundingClientRect().width
      : 0;

    setTabs((f) => {
      const newTabs = [...f];
      let totalWidth =
        SETTING_MENU_ICON_WIDTH + MENU_GAP_WIDTH + hiddenTabsMenuWidth;
      let firstOverflowTabIndex = null;

      newTabs.forEach((tab, i) => {
        // if there is no overflowing tab yet
        if (firstOverflowTabIndex === null) {
          // determine if the current tab overflows
          if (totalWidth + tab.width > navMiddleWidth) {
            newTabs[i].overflow = true;
            firstOverflowTabIndex = tab.index;
          } else {
            newTabs[i].overflow = false;
            totalWidth += tab.width;
          }
          // if there is an overflowing tab already, all the remaining tabs should overflow
        } else if (tab.index > firstOverflowTabIndex) {
          newTabs[i].overflow = true;
        }
      });
      return newTabs;
    });
  };

  // update tabs on resize
  useLayoutEffect(() => {
    const debouncedUpdateTabs = debounce(updateTabs, 200);
    if (isDomNodeType(tabsWrapperRef.current)) {
      window.addEventListener('resize', debouncedUpdateTabs);
    }
    return () => {
      window.removeEventListener('resize', debouncedUpdateTabs);
    };
  }, [tabsWrapperRef.current, updateTabs]);

  // render visible tabs
  const renderTabs = visibleTabs.map((tab, i) => {
    // Note: should retain keyboard tab focus when:
    // - is selected page
    // - selected page is in hidden tabs, the first page
    // - on search page
    const shouldHaveTabFocus =
      selectedPage === i ||
      (hasHiddenTabs && selectedPage >= visibleTabs.length && i === 0) ||
      (window.location.pathname.startsWith('/search') && i === 0);

    return (
      <Tab
        key={`p${i + 1}-${tab.name}`}
        onClick={() => redirect(getRouteFromPageIndex(mappedPages, i))}
        onKeyUp={moveFocus}
        tabIndex={shouldHaveTabFocus ? 0 : -1}
        setTabs={setTabs}
        updateTabs={updateTabs}
        tab={tab}
        menuIndex={i}
        newCount={tab.newCount}
      />
    );
  });

  // reset tabs when pages data changes (reordering etc)
  useEffect(() => {
    setTabs(mappedPages);
  }, [allPages, newUpdatesCount]);

  return (
    <div className={c.tabs}>
      <menu role="tablist">
        <ul ref={tabsWrapperRef}>{renderTabs}</ul>
      </menu>

      {hasHiddenTabs ? (
        <HiddenTabsMenu hiddenTabs={hiddenTabs} ref={hiddenTabsMenuRef} />
      ) : null}
    </div>
  );
};

export default Tabs;
