import {
  Card,
  Logo,
  InlineExternalLink,
  Tag,
  fontStyles,
} from '@monash/portal-react';
import c from './update-card.module.scss';
import fs from 'styles/font-styles.module.scss';
import { getDueText } from '../utils/get-due-text';
import { formatPublishDate } from 'components/utilities/format-publish-date';
import classNames from 'classnames';

const UpdateCard = ({
  title,
  description,
  links,
  isDueSoon,
  isNew,
  dueDate,
  currentDate,
  publishDate,
  ...restProps
}) => {
  const tagColor = isDueSoon ? 'orange' : 'grey';
  const publishDateFormatted = formatPublishDate(currentDate, publishDate);

  return (
    <Card tabIndex={0} {...restProps}>
      <div className={c.topSection}>
        <div className={c.header} aria-label="By Monash University.">
          <div className={c.logoWrapper}>
            <Logo.Shield height={24} aria-hidden />
          </div>
          <div>
            <div className={fontStyles.label}>Monash University</div>
            <div className={classNames(fontStyles.label, c.publishDate)}>
              {publishDateFormatted}
            </div>
          </div>
        </div>

        <div className={c.tags}>
          {isNew && <Tag text="New!" color="blue" />}
          {dueDate && (
            <Tag text={getDueText({ dueDate, currentDate })} color={tagColor} />
          )}
        </div>
      </div>

      <h3 className={classNames(c.title, fs.heading, fs.md)}>{title}</h3>
      <p className={c.description}>{description}</p>

      {links?.map(({ label, url, id }) => {
        const textLabel = label || url;

        return (
          textLabel && (
            <InlineExternalLink
              link={url}
              text={textLabel}
              className={c.link}
              key={id}
            />
          )
        );
      })}
    </Card>
  );
};

export default UpdateCard;
