import { Button, Switch } from '@monash/portal-react';

import { useSessionStorage } from '@monash/portal-frontend-common';
import { MOCK_COURSE_PRESETS } from '../../../providers/data-provider/mock-courses';
import c from '../popup.module.scss';
import { useState } from 'react';

const Data = () => {
  const [fakeData, setFakeData] = useSessionStorage('fakeData', true);
  const [includeIdentityMock, setIncludeIdentityMock] = useSessionStorage(
    'mock:include:identity',
    false
  );
  const [testDate, setTestDate] = useSessionStorage('testDate');
  const [timeMultiplier, setTimeMultiplier] = useSessionStorage(
    'timeMultiplier',
    1
  );
  const currentDate = testDate ? new Date(testDate) : new Date();

  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [day, setDay] = useState(currentDate.getDate());
  const [hours, setHours] = useState(currentDate.getHours());
  const [minutes, setMinutes] = useState(currentDate.getMinutes());
  const [seconds, setSeconds] = useState(currentDate.getSeconds());

  const [fakeCourseDataType, setFakeCourseDataType] =
    useSessionStorage('fakeCourseDataType');

  const updateFakeCourseDataTypeValue = (e) => {
    const newVal = e.target?.value || '';
    setFakeCourseDataType(newVal);
  };

  const onSetClick = () => {
    const date = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    ).getTime();
    setTestDate(date);
    window.location.reload();
  };

  return (
    <div className={c.tabWrapper}>
      <div className={c.inputGroups}>
        <div className={c.inputGroup}>
          <div className={c.label}>Mock courses</div>
          <select
            value={fakeCourseDataType}
            onChange={updateFakeCourseDataTypeValue}
            tabIndex={-1}
          >
            <option value="">No Mock</option>
            {Object.keys(MOCK_COURSE_PRESETS).map((preset, index) => {
              return (
                <option
                  key={`mock-course-data-preset-${index + 1}`}
                  value={preset}
                >
                  {preset}
                </option>
              );
            })}
          </select>
        </div>

        <hr />

        <div>
          <div className={c.label}>Use mock data</div>
          <Switch
            on={fakeData}
            onClick={() => {
              setFakeData(!fakeData);
            }}
          />
        </div>

        {fakeData && (
          <div className={c.indent}>
            <div className={c.inputGroups}>
              <div>
                <div className={c.label}>Include identity mock</div>
                <Switch
                  on={includeIdentityMock}
                  onClick={() => {
                    setIncludeIdentityMock(!includeIdentityMock);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <hr />

        <div className={c.inputGroup}>
          <div className={c.label}>Current date</div>

          <div className={c.dateInputs}>
            <div className={c.inputWrapper}>
              <label htmlFor="test-harness-ui-input-year">Year</label>
              <input
                id="test-harness-ui-input-year"
                type="number"
                min="1"
                max="9999"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                tabIndex={-1}
              />
            </div>

            <div className={c.inputWrapper}>
              <label htmlFor="test-harness-ui-input-month">Month</label>
              <input
                id="test-harness-ui-input-month"
                type="number"
                value={month}
                min="1"
                max="12"
                onChange={(e) => setMonth(e.target.value)}
                tabIndex={-1}
              />
            </div>

            <div className={c.inputWrapper}>
              <label htmlFor="test-harness-ui-input-day">Day</label>
              <input
                id="test-harness-ui-input-day"
                type="number"
                value={day}
                min="1"
                max="31"
                onChange={(e) => setDay(e.target.value)}
                tabIndex={-1}
              />
            </div>

            <div className={c.inputWrapper}>
              <label htmlFor="test-harness-ui-input-hours">Hours</label>
              <input
                id="test-harness-ui-input-hours"
                type="number"
                value={hours}
                min="0"
                max="23"
                onChange={(e) => setHours(e.target.value)}
                tabIndex={-1}
              />
            </div>

            <div className={c.inputWrapper}>
              <label htmlFor="test-harness-ui-input-minutes">Minutes</label>
              <input
                id="test-harness-ui-input-minutes"
                type="number"
                value={minutes}
                min="0"
                max="59"
                onChange={(e) => setMinutes(e.target.value)}
                tabIndex={-1}
              />
            </div>

            <div className={c.inputWrapper}>
              <label htmlFor="test-harness-ui-input-seconds">Seconds</label>
              <input
                id="test-harness-ui-input-seconds"
                type="number"
                value={seconds}
                min="0"
                max="59"
                onChange={(e) => setSeconds(e.target.value)}
                tabIndex={-1}
              />
            </div>
          </div>
        </div>

        <hr />

        <div className={c.inputGroup}>
          <label
            className={c.label}
            htmlFor="test-harness-ui-input-time-multiplier"
          >
            Time multiplier
          </label>

          <div className={c.inputWrapper}>
            <input
              id="test-harness-ui-input-time-multiplier"
              type="number"
              value={timeMultiplier}
              min="1"
              max="100"
              onChange={(e) => setTimeMultiplier(e.target.value)}
              tabIndex={-1}
            />
          </div>
        </div>

        <Button
          className={c.set}
          size="medium"
          onClick={onSetClick}
          tabIndex={-1}
        >
          Set
        </Button>
      </div>
    </div>
  );
};

export default Data;
