import { useContext } from 'react';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { ThemeContext } from 'components/providers/theme-provider/ThemeProvider';
import { themes } from '@monash/portal-react';

export const useBorderBackground = (overlapping, itemRef, borderWidth = 2) => {
  const { customThemeVariables, selectedTheme } = useContext(ThemeContext);
  const { pages, selectedPage } = useContext(PageContext);
  // TODO: rework this to account for default themes with image backgrounds
  const background =
    customThemeVariables.background ||
    themes[selectedTheme].variables['--canvas-bg-color'];

  if (!overlapping) {
    return {
      backgroundColor: 'transparent',
      border: `${borderWidth}px solid transparent`,
    };
  }

  const backgroundType = background?.startsWith('#')
    ? 'solid'
    : background?.startsWith('url')
    ? 'image'
    : 'gradient';

  const bgLeft = -1 * itemRef?.current?.getBoundingClientRect().left;

  const bgTop = -1 * itemRef?.current?.getBoundingClientRect().top;

  const backgroundAttr =
    backgroundType === 'solid'
      ? { backgroundColor: background }
      : { backgroundImage: background };

  return {
    backgroundPosition: `
      left 
      calc(${bgLeft}px - ${5 * selectedPage}vw - ${borderWidth}px) 
      top 
      calc(${bgTop}px - ${borderWidth}px)
    `,
    backgroundSize: `
      ${100 + 5 * pages?.length}vw 
      ${backgroundType === 'image' ? 'auto' : '100vh'}
    `,
    ...backgroundAttr,
    border: `${borderWidth}px solid transparent`,
  };
};
