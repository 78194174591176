import React, { useContext, useEffect } from 'react';
import {
  LoadingIndicator,
  useResponsiveValue,
  QUERIES_SEARCH_RESULT_GRID_FS,
} from '@monash/portal-react';
import { AccessibilityContext, Faq } from '@monash/portal-frontend-common';
import { SearchContext } from 'components/providers/SearchProvider';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import SearchBar from 'components/utilities/search/SearchBar';
import SearchError from './SearchError';
import SearchResultsCard from '../../ui/search-result-card/SearchResultsCard';
import { getSearchResultLiveMsg } from './search-result-utils';
import c from './search-result.module.scss';

// Used to determine where FAQ should be rendered among search results
const ROWS_BEFORE_FAQ = 2;
const FAQ_TITLE = 'Related FAQs';

const SearchResult = () => {
  const { backgroundStyle } = useContext(PageContext);
  const { setAppLivePoliteMsg } = useContext(AccessibilityContext);

  const {
    runSearchCallCount,
    loadingLinks,
    searchTarget,
    searchResult,
    searchError,
    runSearch,
  } = useContext(SearchContext);

  useEffect(() => {
    runSearch();
  }, []);

  const itemsPerRow = useResponsiveValue(QUERIES_SEARCH_RESULT_GRID_FS, 1);
  const itemsBeforeFaq = itemsPerRow * ROWS_BEFORE_FAQ;

  const searchResultGridStyles = {
    '--search-result-grid-col-count': itemsPerRow,
  };

  const searchResultCount = searchResult?.length || 0;
  const searchResultMapFn = (item, i) => {
    return (
      <SearchResultsCard
        entity={item}
        key={item.entityContent.name}
        index={i}
      />
    );
  };

  // Note: have a short dependency list to make sure live msg is only updated when we need to
  // - loadingLinks: to help reset live region when same search executed continuously
  // - runSearchCallCount: to only update live region when each search API call is resolved
  useEffect(() => {
    setAppLivePoliteMsg(
      getSearchResultLiveMsg({
        isLoading: loadingLinks,
        query: searchTarget,
        error: searchError,
        resultCount: searchResult?.length,
      })
    );
    // AX requirement:
    // - when each search call finalised, try focus back onto the search input
    // - so the next 'tab' key navigation will go to 'skip to search result',
    // - which was lost due to mounting the search result page into the DOM freshly each time
    if (!loadingLinks) {
      const searchInputNode = document.querySelector(
        '#searchPageContainer input#search-input[role="searchbox"]'
      );
      searchInputNode && searchInputNode.focus();
    }
  }, [loadingLinks, runSearchCallCount]);

  return (
    <div
      id="searchPageContainer"
      className={c.searchContainer}
      role="main"
      aria-label="Search"
    >
      <div className={c.page}>
        <div className={c.resultSearchBar}>
          <SearchBar
            hasClearButton={true}
            hasSkipToContent={searchResultCount > 0}
          />
        </div>
        {loadingLinks && (
          <div className={c.loading}>
            <LoadingIndicator color="var(--canvas-text-color)" />
          </div>
        )}
        {!loadingLinks && searchError && <SearchError />}
        {!loadingLinks && (
          <div
            id="searchResultContainer"
            className={c.searchResult}
            tabIndex="-1"
          >
            <div className={c.header}>
              <div tabIndex="-1" id="searchResult" role="status">
                {searchResultCount}
                {` result${searchResultCount > 1 ? 's' : ''}`}
              </div>
            </div>
            <div className={c.links} style={searchResultGridStyles}>
              {searchResult?.slice(0, itemsBeforeFaq).map(searchResultMapFn)}
            </div>
            <section className={c.faq} aria-label={FAQ_TITLE}>
              <Faq searchTerm={searchTarget} title={FAQ_TITLE} />
            </section>
            {searchResultCount > itemsBeforeFaq && (
              <div className={c.links} style={searchResultGridStyles}>
                {searchResult.slice(itemsBeforeFaq).map(searchResultMapFn)}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={c.background} style={backgroundStyle} />
    </div>
  );
};

export default SearchResult;
