import { useCallback, useEffect } from 'react';
import { isDomNodeType, isStringEmpty } from '@monash/portal-frontend-common';

export const ATTR_IGNORE_NODE = 'data-key-nav-group-ignored';
export const KEY_NAV_MODE = {
  LEFT_RIGHT: 'LEFT_RIGHT',
  UP_DOWN: 'UP_DOWN',
  BOTH: 'BOTH',
};

const KEY_NAV_DIR = {
  PREV: 'PREV',
  NEXT: 'NEXT',
};

/**
 * useKeyNavGroups
 * @param {Object} rootRef - keyboard navigation root container ref
 * @param {string} rootSelector - keyboard navigation root container selector
 * @param {string} groupSelector - keyboard navigation groups selector
 * @param {string} groupIgnoreNodeAttr - node attribute name ignored by key nav
 * @param {boolean} isDisabled - toggles behaviour ON/OFF
 * @param {string} keyNavMode - keyboard navigation mode
 * @description Use this hook to enhance AX keyboard navigation flow for items
 * within group containers. It enables when any focusable elements within the
 * supplied root container is focused, and using arrow keys will help navigate
 * directly via group containers defined by selectors, so that users don't need
 * to tab through all elements from one group to get to the next group.
 */
const useKeyNavGroups = ({
  rootRef = null,
  rootSelector = '',
  groupSelector = '',
  groupIgnoreNodeAttr = ATTR_IGNORE_NODE,
  keyNavMode = KEY_NAV_MODE.LEFT_RIGHT,
  isDisabled = false,
}) => {
  const handleKeyDown = useCallback(
    (e) => {
      // exit if not from supported keys
      if (
        (keyNavMode === KEY_NAV_MODE.LEFT_RIGHT &&
          e.key !== 'ArrowLeft' &&
          e.key !== 'ArrowRight') ||
        (keyNavMode === KEY_NAV_MODE.UP_DOWN &&
          e.key !== 'ArrowUp' &&
          e.key !== 'ArrowDown') ||
        (keyNavMode === KEY_NAV_MODE.BOTH &&
          e.key !== 'ArrowLeft' &&
          e.key !== 'ArrowRight' &&
          e.key !== 'ArrowUp' &&
          e.key !== 'ArrowDown') ||
        (keyNavMode !== KEY_NAV_MODE.LEFT_RIGHT &&
          keyNavMode !== KEY_NAV_MODE.UP_DOWN &&
          keyNavMode !== KEY_NAV_MODE.BOTH)
      ) {
        return;
      }

      const navDir =
        e.key === 'ArrowLeft' || e.key === 'ArrowUp'
          ? KEY_NAV_DIR.PREV
          : KEY_NAV_DIR.NEXT;

      // get root node
      const rootNode = isStringEmpty(rootSelector)
        ? rootRef?.current
        : document.querySelector(rootSelector);

      if (!isDomNodeType(rootNode)) return;
      // get columns nodes
      let groupNodes = document.querySelectorAll(groupSelector);
      if (groupNodes.length === 0) return;
      groupNodes = Array.from(groupNodes);
      // get activeElement
      const activeNode = document.activeElement;
      if (activeNode === null) return;
      const ignoreNodeAttr = isStringEmpty(groupIgnoreNodeAttr)
        ? ATTR_IGNORE_NODE
        : groupIgnoreNodeAttr;
      if (activeNode.hasAttribute(ignoreNodeAttr)) return;
      const rootContainsActiveNode = rootNode.contains(activeNode);
      if (!rootContainsActiveNode) return;
      // get current column node
      const currentGroupNode = activeNode.closest(groupSelector);
      if (currentGroupNode === null) return;
      const currentGroupNodeIndex = groupNodes.indexOf(currentGroupNode);
      if (currentGroupNodeIndex === -1) return;

      // work out nav target column index
      let targetGroupNodeIndex =
        currentGroupNodeIndex + (navDir === KEY_NAV_DIR.PREV ? -1 : 1);
      if (targetGroupNodeIndex < 0) targetGroupNodeIndex = 0;
      if (targetGroupNodeIndex >= groupNodes.length)
        targetGroupNodeIndex = groupNodes.length - 1;

      // nav target node
      const targetGroupNode = groupNodes[targetGroupNodeIndex];
      if (targetGroupNode.getAttribute('tabindex') === null) {
        targetGroupNode.setAttribute('tabindex', '-1');
      }
      targetGroupNode.focus();
    },
    [rootRef, rootSelector, groupSelector, keyNavMode]
  );

  useEffect(() => {
    if (!isDisabled) {
      // Notes:
      // use 'capture' phase here to avoid interference
      // from 'target' and 'bubbling' phase listeners
      document.addEventListener('keydown', handleKeyDown, true);
      return () => {
        document.removeEventListener('keydown', handleKeyDown, true);
      };
    }
  }, [isDisabled, handleKeyDown]);
};

export default useKeyNavGroups;
