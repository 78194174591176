import React, { useState, useContext } from 'react';
import { useInterval } from 'hooks/use-interval';
import c from './clock.module.scss';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.svg';
import { Icon } from '@monash/portal-react';
import { WidgetContext } from 'components/providers/WidgetProvider';
import EditClockBackground from './edit-clock-background/EditClockBackground';
import useLiveDate from 'hooks/use-live-date';

const TIME_UNIT = {
  H: 'hour',
  M: 'minute',
  S: 'second',
};

const COLOURS = [
  {
    id: 'auto',
    value: 'var(--card-text-color)',
  },
  {
    id: 'black',
    value: '#171717',
  },
  {
    id: 'white',
    value: '#FFFFFF',
  },
  {
    id: 'green',
    value: '#A2FB91',
  },
  {
    id: 'pink',
    value: '#F4B5B4',
  },
  {
    id: 'purple',
    value: '#A0B5F8',
  },
  {
    id: 'yellow',
    value: '#F8EFA2',
  },
  {
    id: 'blue',
    value: '#A2EEFD',
  },
];

const Clock = ({
  updateData,
  widgetId,
  data,
  escapeSettingActionsFocusRef,
}) => {
  // Extract colour code from clock data
  // If a colour cannot be found, use black
  const clockColour =
    COLOURS.find((option) => option.id === data?.colour)?.value || '#171717';

  const { editClockBackgroundActiveWidget } = useContext(WidgetContext);
  const { get } = useLiveDate();
  const [currentDate, setCurrentDate] = useState(new Date(get()));
  const time = {
    hour: currentDate.getHours(),
    minute: currentDate.getMinutes(),
    second: currentDate.getSeconds(),
  };

  // update time every second
  useInterval(() => {
    setCurrentDate(new Date(get()));
  }, 1000);

  const standardHands = [
    {
      class: TIME_UNIT.H,
      rotationDegree: (time.hour * 3600 + time.minute * 60 + time.second) / 120,
    },
    {
      class: TIME_UNIT.M,
      rotationDegree: (time.minute * 60 + time.second) / 10,
    },
    {
      class: TIME_UNIT.S,
      rotationDegree: time.second * 6,
    },
  ];

  // hands
  const renderHands = (hands) =>
    hands.map((hand, i) => (
      <div
        key={i}
        className={c.containerBox}
        style={{
          transform: `rotate(${hand.rotationDegree}deg)`,
        }}
      >
        <div className={c[hand.class]}>
          <div
            className={`${c.handShadow} ${
              hand.class === 'second' ? c.long : null
            }`}
            style={{
              transform: `rotate(-${hand.rotationDegree}deg) translate(7px,0) rotate(${hand.rotationDegree}deg)`,
            }}
          />
        </div>
      </div>
    ));

  // markers
  const renderMarkerPairs = (degrees) =>
    degrees.map((degree, i) => (
      <div
        key={i}
        className={c.markersPair}
        style={{
          transform: `rotate(${degree}deg)`,
        }}
      />
    ));

  return (
    <div
      className={c.clock}
      style={{
        '--clock-colour': clockColour,
      }}
    >
      {editClockBackgroundActiveWidget === widgetId ? (
        <EditClockBackground
          widgetId={widgetId}
          data={data}
          updateData={updateData}
          escapeFocusRef={escapeSettingActionsFocusRef}
        />
      ) : null}
      <div className={c.sizeContainer}>
        <div className={c.frame}>
          <div
            className={c.face}
            style={
              data?.backgroundURL
                ? {
                    backgroundImage: `url(${data?.backgroundURL})`,
                  }
                : {}
            }
          >
            <div className={c.circleBottom} />
            <div className={c.indicators}>
              {renderMarkerPairs([60, 120, 210, 330])}
              <span>12</span>
              <div className={c.mid}>
                <span>9</span>
                <span>3</span>
              </div>
              <span>6</span>
            </div>
            <div className={c.hands}>{renderHands(standardHands)}</div>
            <div className={c.circleTop} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ClockModule = {
  component: Clock,
  name: 'Clock',
  icon: WidgetIcon.Clock,
  previewImage: preview,
  previewBackgroundColor: '#E8EEF4',
  description: 'A clock you can customise', // REMOVE description AND UPDATE descriptionUplift to description WHEN REMOVE FEATURE FLAG
  descriptionUplift:
    'An analogue clock you can customise with colour and images',
  defaultSize: 'S',
  additionalOptions: [
    {
      key: 'colour',
      name: 'Colour',
      editType: 'colour',
      default: 'auto',
      options: COLOURS,
    },

    {
      key: 'backgroundURL',
      editType: null,
      default: null,
    },
  ],
  additionalActions: [
    {
      action: 'editClockBackground',
      icon: <Icon.Pencil size={20} />,
      text: 'Edit background',
      haspopup: 'dialog',
    },
  ],
};

export default ClockModule;
