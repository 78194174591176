// This function can be used for when new default pages are added to Home
// behind a toggle. It will add or remove the page depending on the toggle state.
export const handleToggleForDefaultPage = ({ pageId, pages, isToggledOn }) => {
  const pageOrder = pages?.pageOrder || [];
  const hasPageId = pageOrder.includes(pageId);

  const pageOrderWithoutPageId = pageOrder?.filter(
    (currentPageId) => currentPageId !== pageId
  );
  const pageOrderWithPageId = hasPageId ? pageOrder : [...pageOrder, pageId];

  // Stop the initial flash of just the toggled on page whilst
  // page data is still loading.
  if (!pageOrder.length) {
    return [];
  }

  return {
    ...pages,
    pageOrder: isToggledOn ? pageOrderWithPageId : pageOrderWithoutPageId,
  };
};
