import { useContext, useEffect, useRef, useState } from 'react';
import { Card } from '@monash/portal-react';
import Accent from 'components/ui/accent/Accent';
import { renderEventDetails } from 'components/utilities/user-events/render-event-details';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import c from './upcoming-listing-mobile.module.scss';
import cs from './upcoming-listing-shared.module.scss';
import UserEventCTAs from 'components/utilities/user-events/UserEventCTAs';
import { eventsOnDay, getTitle, formatEventTitleAbbr } from '../utils/utils';
import { getDisplayStatus } from 'components/utilities/user-events/render-status';
import { getTimeUntil } from 'components/utilities/user-events/get-time-until';
import { getDisplayDate } from 'components/utilities/user-events/get-display-date';
import WarningBox from 'components/ui/WarningBox/WarningBox';
import fs from 'styles/font-styles.module.scss';
import { getUserEventsErrorMessage } from 'components/providers/data-provider/utils/getUserEventsError';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { NEXT_EVENT_PREFIX } from '../constant';
import AssessmentsWarning from 'components/ui/AssessmentsWarning/AssessmentsWarning';

const UpcomingListingMobile = ({
  currentDate,
  events,
  allEvents,
  userEventsError,
}) => {
  const [cardHeight, setCardHeight] = useState(0);
  const { unitTitles } = useContext(DataContext);
  const headerRef = useRef(null);
  const warningRef = useRef(null);
  const TOP_PADDING = 140;
  const BOTTOM_PADDING = 16;
  const WARNING_PADDING = 16;

  const updateCardHeight = () => {
    const headerHeight = headerRef.current?.getBoundingClientRect().height || 0;
    const warningHeight =
      warningRef.current?.getBoundingClientRect().height + WARNING_PADDING || 0;
    const newCardHeight =
      window.innerHeight -
      headerHeight -
      warningHeight -
      TOP_PADDING -
      BOTTOM_PADDING;
    setCardHeight(newCardHeight);
  };

  // This code is necessary purely for iOS safari, where after an
  // orientation change (portrait -> landscape or vice versa), the card height
  // is not being updated.
  useEffect(() => {
    updateCardHeight();

    window.addEventListener('resize', updateCardHeight);

    return () => window.removeEventListener('resize', updateCardHeight);
  }, []);

  const userEventsErrorMessage = getUserEventsErrorMessage(userEventsError);
  const eventsOnToday = eventsOnDay(events, currentDate);
  const isSingularUpcomingEvent = events.length === 1 && eventsOnToday;

  const getEventCards = () => {
    return events
      .sort((a, b) => a.start.time - b.start.time)
      .map((event) => {
        const { data } = event || {};
        const { status } = data;
        const submissionStartDate = data.submissionStartDate?.time;
        const displayStatus = getDisplayStatus({
          status,
          currentDate,
          submissionStartDate,
        });
        const showTimeDistance =
          currentDate < event.start.time && displayStatus !== 'SUBMITTED';
        const eventTitle = getTitle(event);
        const eventTitleFormatted = formatEventTitleAbbr(eventTitle, {
          'aria-label': getTitle(event, true),
        });

        return (
          <Card
            key={event.id}
            className={c.eventCard}
            // if there's only one upcoming event, make the card take up full screen
            style={
              isSingularUpcomingEvent
                ? {
                    width: 'calc(100vw - 32px)',
                    maxWidth: 'calc(100vw - 32px)',
                    height: cardHeight,
                  }
                : { height: cardHeight }
            }
          >
            <div className={c.eventCardContents}>
              {showTimeDistance && (
                <div data-testid="time-until" className={c.timeUntil}>
                  {getTimeUntil({ event, currentDate })}
                </div>
              )}

              <div className={cs.classHeader}>
                <Accent unitCode={event.display?.unit} />
                <div className={cs.classHeaderText}>
                  <h3
                    className={`${c.activeTitle} ${fs.heading} ${fs.md} ${fs.bold}`}
                  >
                    {eventTitleFormatted}
                  </h3>
                  <div className={c.unitTitle}>
                    {capitaliseFirstWord(
                      data.unitTitle || unitTitles?.[data.unitCode]
                    )}
                  </div>
                </div>
              </div>

              <div className={c.details}>
                {renderEventDetails({
                  event,
                  allEvents,
                  currentDate,
                  stylesheet: c,
                  mode: 'card',
                  largeTimeDisplay: true,
                  includeTimeDist: false,
                })}
              </div>

              <div className={c.actions}>
                <UserEventCTAs eventData={event} mode="card" />
              </div>
            </div>
          </Card>
        );
      });
  };

  return (
    <div className={c.upcomingListingMobile}>
      {userEventsErrorMessage && (
        <WarningBox
          ref={warningRef}
          className={`${c.warningBox} ${cs.warningBox}`}
        >
          {userEventsErrorMessage}
        </WarningBox>
      )}

      <div className={c.events}>
        {/* Section for "no events today" card */}
        {!eventsOnToday && (
          <div className={c.section}>
            <div className={c.listingHeading}>
              <h2>{getDisplayDate(currentDate, currentDate)}</h2>
            </div>
            <Card
              className={`${c.eventCard} ${c.noEventsToday}`}
              style={{ height: cardHeight }}
            >
              <div className={fs.heading}>No events today</div>
              <div>
                {getTimeUntil({
                  event: events[0],
                  currentDate,
                  prefixString: NEXT_EVENT_PREFIX,
                })}
              </div>
            </Card>
          </div>
        )}

        {/* Section for regular event cards */}
        <div className={c.section}>
          <div ref={headerRef} className={c.listingHeading}>
            <h2>{getDisplayDate(currentDate, events[0]?.start.time)}</h2>
          </div>
          {getEventCards()}
        </div>
      </div>
      <div className={c.assessmentsWarning}>
        <AssessmentsWarning />
      </div>
    </div>
  );
};

export default UpcomingListingMobile;
