export const USER_TYPE = {
  GENERAL: 'general',
  COLLEGE: 'college',
};

export const USER_LABEL = {
  AU: 'Australia',
  MY: 'Malaysia',
  IDN: 'Indonesia',
  MC: 'Monash College',
};
