import React, { useState, useRef } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalSection,
  useResponsiveValue,
} from '@monash/portal-react';
import c from './widget-library-uplift.module.scss';
import AddWidgetTrigger from './AddWidgetTrigger';
import { getWidgetList } from './utils';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const WidgetLibrary = ({
  pageId,
  setNewWidgetId,
  setWidgetOrder,
  widgetDirectory,
  scrollToHeader,
}) => {
  const widgetList = getWidgetList(widgetDirectory);
  const [isShown, setIsShown] = useState(false);
  const triggerRef = useRef();
  const openMenu = () => {
    setIsShown(true);
  };
  const closeMenu = () => {
    setIsShown(false);
    triggerRef.current.focus();
  };

  // Mobile responsive
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const menuLabel = 'Add widget';

  return (
    <div>
      {responsiveSize === 'S' ? (
        <IconButton
          size={24}
          icon={Icon.Plus}
          onClick={openMenu}
          ref={triggerRef}
          mode="canvas"
          aria-expanded={isShown}
          aria-label="Add widget"
          aria-haspopup="dialog"
        />
      ) : (
        <Button
          ref={triggerRef}
          size="medium"
          icon={<Icon.Plus />}
          onClick={openMenu}
          mode="canvas"
          variant="text"
          aria-haspopup="menu"
          className={c.entryBtn}
          data-tracking-event="custom-add-widget"
        >
          {menuLabel}
        </Button>
      )}

      <Modal
        open={isShown}
        onClose={closeMenu}
        dismissOnHistoryNav={true}
        ariaLabel="Widget library"
        ariaDescribedby={null}
      >
        <ModalSection
          title="Add widget"
          titleTabIndex={null}
          ariaLabelledby="widgetLibraryModalTitle"
          ariaDescribedby="widgetLibraryModalContent"
          noPadding
        >
          <div className={c.widgetLibrary}>
            <ul className={c.widgetList} aria-label="Widgets">
              {widgetList.map((item, i) => (
                <AddWidgetTrigger
                  key={i}
                  widget={item}
                  pageId={pageId}
                  setNewWidgetId={setNewWidgetId}
                  closeMenus={closeMenu}
                  setWidgetOrder={setWidgetOrder}
                  scrollToHeader={scrollToHeader}
                />
              ))}
            </ul>
          </div>
        </ModalSection>
      </Modal>
    </div>
  );
};

export default WidgetLibrary;
