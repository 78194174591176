import { differenceInHours, format, isAfter } from 'date-fns';

export const formatPublishDate = (currentDate, published) => {
  const publishDate = new Date(published);
  const distance = differenceInHours(currentDate, publishDate);
  const isPublishDateInFuture = isAfter(publishDate, currentDate);

  if (!isPublishDateInFuture) {
    if (distance === 0) {
      return 'Just now';
    }
    if (distance < 24 && distance > 0) {
      return `${distance}hr ago`;
    }
  }

  return format(publishDate, 'd MMMM yyyy');
};
