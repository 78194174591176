import React, { useContext } from 'react';
import { isObject, isStringEmpty } from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import s from './accent.module.scss';

/**
 * Accent
 * @param {string} unitCode - unit code text key
 * @param {string} size - size variant token
 * @returns {JSX.Element}
 * @constructor
 */
const Accent = ({ unitCode = '', size = '', orientation = '' }) => {
  const { loading, unitColours } = useContext(DataContext);

  // work out unit colour value
  const unitColour = isObject(unitColours) ? unitColours[unitCode] || '' : '';
  const hasUnitColour = !isStringEmpty(unitColour);

  // prep custom styles
  const customStyles =
    !loading.unitColours && hasUnitColour
      ? {
          // loaded and has unit colour
          opacity: '1',
          backgroundImage: 'none',
          backgroundColor: unitColour,
        }
      : {
          // still loading, or
          // loaded but no unit colour
        };

  return (
    <div
      className={s.AccentContainer}
      data-loading={loading.unitColours ? 'true' : 'false'}
      data-size={!isStringEmpty(size) ? size : ''}
      data-orientation={!isStringEmpty(orientation) ? orientation : ''}
      style={{
        ...customStyles,
      }}
    />
  );
};

export default Accent;
