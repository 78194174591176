import React, {
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  Icon,
  IconButton,
  Modal,
  ModalSection,
  useResponsiveValue,
} from '@monash/portal-react';
import {
  capitaliseFirstWord,
  isObject,
  useOnOutsideClick,
  FeatureContext,
} from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import c from './schedule-card.module.scss';
import RelativePanel from 'components/ui/relative-panel/RelativePanel';
import SimpleFocusTrap from 'components/ui/simple-focus-trap/SimpleFocusTrap';
import Accent from 'components/ui/accent/Accent';
import { renderEventDetails } from 'components/utilities/user-events/render-event-details';
import UserEventCTAs from 'components/utilities/user-events/UserEventCTAs';
import {
  getTitle,
  formatEventTitleAbbr,
} from 'components/pages/upcoming/utils/utils';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

export const SCHEDULE_CARD_INSTANCE = {
  UNKNOWN: 'UNKNOWN',
  CALENDAR: 'CALENDAR',
  ASSESSMENTS: 'ASSESSMENTS',
};

const ScheduleCard = ({
  instance = SCHEDULE_CARD_INSTANCE.UNKNOWN,
  shown,
  setShown,
  scheduleItemRef,
  displayData,
}) => {
  const { userEvents, currentDate, unitTitles, gradeScaleTypes } =
    useContext(DataContext);
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const { featureFlags } = useContext(FeatureContext);

  const handleClose = useCallback(
    (ignoreTriggerFocus = false) => {
      if (!ignoreTriggerFocus) {
        const goBackTo = scheduleItemRef?.current;
        goBackTo?.focus();
      }
      setShown(false);
    },
    [scheduleItemRef]
  );

  const handleDismiss = useCallback(() => {
    // ignore trigger focus on auto dismiss
    handleClose(true);
  }, [handleClose]);

  const containerRef = useRef();
  const closeButtonRef = useRef();
  useOnOutsideClick({
    selectors:
      '[data-tag=schedule-item-card], [data-tag=schedule-popup], [data-tag=key-date]',
    fn: handleClose,
  });

  const onKeyDownContainer = useCallback(
    (e) => {
      if (shown) {
        if (e.code === 'Escape') {
          e.preventDefault();
          handleClose();
        }
      }
    },
    [handleClose, shown]
  );

  const onKeyDownX = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClose();
    }
  };

  useEffect(() => {
    if (shown && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [shown, closeButtonRef.current]);

  const isMobileBP = responsiveSize === 'S';
  const modalTitleId = `${instance.toLowerCase()}ScheduleCardTitle`;
  const modalContentId = `${instance.toLowerCase()}ScheduleCardContent`;
  const headingAriaLabel = displayData?.keyDate
    ? displayData.title
    : isObject(displayData)
    ? getTitle(displayData, true)
    : null;

  const headerContent = useMemo(() => {
    // key date
    if (displayData?.keyDate) {
      return (
        <div className={c.headerContent}>
          <h3>{displayData.title}</h3>
        </div>
      );
    }

    const displayDataTitle = isObject(displayData)
      ? getTitle(displayData)
      : null;
    const displayDataTitleFormatted = formatEventTitleAbbr(displayDataTitle);

    // default
    return (
      <>
        <Accent unitCode={displayData?.display.unit} />
        <div className={c.headerContent}>
          <h3 aria-label={headingAriaLabel}>
            {/* Show proper title if a schedule card is open */}
            {displayDataTitleFormatted}
          </h3>
          <p>
            {capitaliseFirstWord(
              displayData?.data?.unitTitle ||
                unitTitles?.[displayData?.data.unitCode]
            )}
          </p>
        </div>
      </>
    );
  }, [displayData]);

  const renderBodyContent = (renderMobile = isMobileBP) => {
    // key date
    if (displayData?.keyDate) {
      return (
        <div className={c.keyDate} tabIndex={renderMobile ? -1 : 0}>
          {displayData?.desc && (
            <div>
              <div className={c.label}>Detail</div>
              <div>{displayData.desc}</div>
            </div>
          )}
          <div>
            <div className={c.label}>Event</div>
            <div>University key dates</div>
          </div>
        </div>
      );
    }

    if (displayData) {
      return (
        <>
          <div className={c.content} tabIndex={renderMobile ? -1 : 0}>
            {renderEventDetails({
              event: displayData,
              allEvents: userEvents,
              currentDate,
              stylesheet: c,
              mode: 'card', // TODO: replace all instances of "card"/"canvas" with enum
              gradeScaleTypes,
              featureFlags,
            })}
          </div>
          <div className={c.actions}>
            <UserEventCTAs eventData={displayData} mode="card" />
          </div>
        </>
      );
    }

    return null;
  };

  const renderDesktopView = () => {
    return (
      <SimpleFocusTrap trapIsOn={shown}>
        <RelativePanel
          relativeElementRef={scheduleItemRef}
          shown={shown}
          offset={10}
          borderRadius="16px"
          minWidthNum={360}
          wrapperClassNames={[c.relativePanelCard]}
          panelRef={containerRef}
          handleKeyDown={onKeyDownContainer}
          dismissOnHistoryNav={true}
          onDismiss={handleDismiss}
          extraWrapperProps={{
            role: 'dialog',
            'aria-label': headingAriaLabel,
          }}
        >
          {displayData && (
            <div className={c.scheduleCard} data-tag="schedule-popup">
              <div className={c.classHeader}>
                {headerContent}
                <IconButton
                  className={c.closeButton}
                  ref={closeButtonRef}
                  onClick={handleClose}
                  aria-label="Close"
                  icon={Icon.X}
                  onKeyDown={onKeyDownX}
                />
              </div>
              {renderBodyContent()}
            </div>
          )}
        </RelativePanel>
      </SimpleFocusTrap>
    );
  };

  const renderMobileView = () => {
    return (
      <Modal
        open={shown}
        onClose={handleClose}
        dismissOnHistoryNav={true}
        ariaLabel={headingAriaLabel}
        ariaDescribedby={null}
      >
        <ModalSection
          title={<div className={c.classHeader}>{headerContent}</div>}
          titleTabIndex={null}
          ariaLabelledby={`${modalTitleId}Mobile`}
          ariaDescribedby={`${modalContentId}Mobile`}
        >
          <div className={c.scheduleCard}>{renderBodyContent()}</div>
        </ModalSection>
      </Modal>
    );
  };

  return isMobileBP ? renderMobileView() : renderDesktopView();
};

export default ScheduleCard;
