export const checkMatchesUserGroups = ({
  citizenship,
  studentsUserGroups,
  updatesUserGroups,
}) => {
  if (!citizenship || !studentsUserGroups) {
    return null;
  }

  // Filter out groups that don't match citizenship
  const filteredUpdatesUserGroups = updatesUserGroups.filter((group) => {
    // Malaysia and Indonesia don't have citizenship property
    if (!group.citizenship) {
      return true;
    }
    return ['All', citizenship].includes(group.citizenship);
  });

  // We do not need to match "all" user groups to undergraduate and postgraduate, as users in each are automatically added to the corresponding "all" user group.
  return Boolean(
    filteredUpdatesUserGroups.find((group) =>
      studentsUserGroups.includes(group.code)
    )
  );
};
