const WIDGET_ERROR_MESSAGE = {
  GENERIC: "Your data isn't available right now - please check back later",
  CODE_BROKEN:
    'There seems to be an issue with this widget - please check back later',
  M_PASS:
    "Your account information isn't available right now - please check back later",
  ASSESSMENTS:
    "We can't show your assessments right now - please check back later or check <a href='https://learning.monash.edu' target='_blank' aria-description='Opens in a new window'>Moodle</a>",
};

export default WIDGET_ERROR_MESSAGE;
