import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import c from './library.module.scss';
import RelativePanel from 'components/ui/relative-panel/RelativePanel';
import { createPortal } from 'react-dom';
import { useOnOutsideClick } from '@monash/portal-frontend-common';
import SimpleFocusTrap from 'components/ui/simple-focus-trap/SimpleFocusTrap';
import useContainerWidth, {
  BOX_SIZE_MODE,
  CONTAINER_WIDTH,
} from 'hooks/use-container-width';

/**
 * Shared component for the Loans and Requests components for the 'My library' widget.
 * Provides a common layout for a scrollable list of given items, as well as a popup element
 * @param {JSX.Element} ItemTile Item tile to be displayed in scrollable list
 * @param {JSX.Element} ItemPopup Component to be displayed in the popup
 * @param {Array} itemsData Array of objects, with each object containing data for a single item
 * @returns {JSX.Element}
 */
const ItemList = ({ ItemTile, ItemPopup, itemsData }) => {
  const [popupData, setPopupData] = useState(null);
  const [popupShown, setPopupShown] = useState(false);
  const [activeItemRef, setActiveItemRef] = useState(null);
  const itemsRefs = useMemo(
    () => itemsData?.map(() => createRef()),
    [itemsData]
  );

  const popupRef = useRef();

  useOnOutsideClick({ refs: [popupRef], fn: () => setPopupShown(false) });

  useEffect(() => {
    if (popupShown) {
      popupRef.current?.focus();
    }
  }, [popupShown]);

  // show a compressed view of the item tiles if the container width falls under some threshold
  const { containerCallbackRef, size } = useContainerWidth({
    sizes: [
      { name: CONTAINER_WIDTH.SMALL, maxWidth: 300 },
      { name: CONTAINER_WIDTH.LARGE },
    ],
    boxSizeMode: BOX_SIZE_MODE.BORDER,
  });

  const handlePopupKeyDown = (e) => {
    if (e.code === 'Escape') {
      setPopupShown(false);
      activeItemRef.current.focus();
    }
  };

  const handleDismiss = useCallback(() => {
    setPopupShown(false);
  }, [setPopupShown]);

  // memoize the panel so that it doesn't unnecessarily reposition if the library widget is re-rendered
  const panel = useMemo(() => {
    return (
      <RelativePanel
        panelRef={popupRef}
        shown={popupShown}
        relativeElementRef={activeItemRef}
        offset={10}
        borderRadius="16px"
        minWidthNum={360}
        wrapperClassNames={c.wrapper}
        handleKeyDown={handlePopupKeyDown}
        dismissOnHistoryNav={true}
        onDismiss={handleDismiss}
      >
        <ItemPopup
          data={popupData}
          shown={popupShown}
          setShown={setPopupShown}
          triggerRef={activeItemRef}
        />
      </RelativePanel>
    );
  }, [popupData, popupShown]);

  return (
    <>
      <ul ref={containerCallbackRef} className={c.itemList}>
        {itemsData.map((itemData, i) => (
          <li key={`library-widget-item-${i}`}>
            <button
              ref={itemsRefs[i]}
              type="button"
              className={
                activeItemRef === itemsRefs[i] && popupShown
                  ? `${c.unstyledButton} ${c.itemButton} ${c.active}`
                  : `${c.unstyledButton} ${c.itemButton}`
              }
              onClick={() => {
                setPopupData(itemData);
                setPopupShown(true);
                setActiveItemRef(itemsRefs[i]);
              }}
            >
              <span
                className={
                  size === CONTAINER_WIDTH.SMALL
                    ? `${c.itemContainer} ${c.vertical}`
                    : c.itemContainer
                }
              >
                <ItemTile data={itemData} />
              </span>
            </button>
          </li>
        ))}
      </ul>
      {popupData &&
        createPortal(
          <SimpleFocusTrap trapIsOn={popupShown}>{panel}</SimpleFocusTrap>,
          document.body
        )}
    </>
  );
};

export default ItemList;
