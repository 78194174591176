import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useMutationObserver } from 'ahooks';
import useSwipeEvents from 'beautiful-react-hooks/useSwipeEvents';
import {
  differenceInDays,
  endOfMonth,
  startOfMonth,
  sub,
  format,
  add,
  getMonth,
  isSameDay,
  isSameMonth,
  getYear,
  getDaysInMonth,
  previousMonday,
  isMonday,
  isDate,
  subMonths,
  addMonths,
} from 'date-fns';
import {
  useOnOutsideClick,
  isNumber,
  isDomNodeType,
  isFunction,
} from '@monash/portal-frontend-common';
import { Icon } from '@monash/portal-react';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { getCurrentDate } from 'components/providers/data-provider/utils';
import { getDayCellScreenReaderMsg } from './utils';
import useKeyNavGrid from 'hooks/use-key-nav-grid';
import SimpleFocusTrap from 'components/ui/simple-focus-trap/SimpleFocusTrap';
import Transition from './Transition';
import Cell, { CELL_VARIANTS } from './cell/Cell';
import c from './mini-calendar.module.scss';

// constants
const dayOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
const MINI_CALENDAR_DEFAULT_HEIGHT = 300;

const MiniCalendar = ({
  open,
  setOpen,
  triggerRef,
  weeks,
  numberOfDisplayDays,
  selectedDay,
  setSelectedDay,
}) => {
  // Add unit colour to calendar
  const { unitColours } = useContext(DataContext);

  // Flat the data to make a map for available dates and daily events
  const createFlatSchedule = () => {
    const flatSchedule = {};
    for (const i in weeks) {
      for (const j in weeks[i].days) {
        flatSchedule[weeks[i].days[j].date] = weeks[i].days[j].items;
      }
    }
    return flatSchedule;
  };

  // Create flat data list after weeks loaded
  const [flatSchedule, setFlatSchedule] = useState();
  useEffect(() => {
    setFlatSchedule(createFlatSchedule());
  }, [weeks]);

  const containerRef = useRef();
  const { onSwipeLeft, onSwipeRight } = useSwipeEvents(containerRef, {
    threshold: 25,
    preventDefault: false,
  });

  onSwipeLeft(() => {
    const nextMonth = startOfMonth(addMonths(selectedDay, 1));
    setSelectedDay(nextMonth);
  });
  onSwipeRight(() => {
    const previousMonth = startOfMonth(subMonths(selectedDay, 1));
    setSelectedDay(previousMonth);
  });

  useOnOutsideClick({
    refs: [triggerRef, containerRef],
    fn: () => setOpen(false),
  });

  // Date values
  const today = new Date(getCurrentDate());
  const todayOrUserSelectedDay = isDate(selectedDay) ? selectedDay : today;
  const [selectedMonth, setSelectedMonth] = useState(todayOrUserSelectedDay);

  useEffect(() => {
    isDate(selectedDay) && setSelectedMonth(selectedDay);
  }, [selectedDay]);

  // Display Calendar
  const startDate = startOfMonth(selectedMonth);
  const endDate = endOfMonth(selectedMonth);
  const lastMonth = sub(selectedMonth, { months: 1 });
  const nextMonth = add(selectedMonth, { months: 1 });
  const numberOfDays = differenceInDays(endDate, startDate) + 1;
  const numberOfDaysLastMonth = getDaysInMonth(lastMonth);
  const prefixDays = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;
  const suffixDays = 6 - endDate.getDay() + 1;

  // Initial day to focus on when calendar opens
  // 1. today or any user selected day
  // 2. use start of selected month when today or user selected day is not in selected month
  const initialDayUponOpen = isSameMonth(todayOrUserSelectedDay, selectedMonth)
    ? todayOrUserSelectedDay
    : startDate;

  // All displayed dates utils
  const nextDay = (day, number) => add(day, { days: number });
  const isIncludesSameDay = (day1, day2) => {
    const startingDay =
      numberOfDisplayDays === 7
        ? isMonday(day2)
          ? day2
          : previousMonday(day2)
        : day2;

    for (let i = 0; i < numberOfDisplayDays; i++) {
      if (isSameDay(day1, nextDay(startingDay, i))) {
        return true;
      }
    }
  };

  // try focus on a initial day cell when open
  useEffect(() => {
    if (open) {
      // force navigate to month of initial day so it can focused on
      setSelectedMonth(initialDayUponOpen);
    }
  }, [open]);

  useLayoutEffect(() => {
    if (open) {
      // try find initial day cell node and focus
      const initialDayNode = containerRef?.current?.querySelector(
        'button[data-variant="DAY"][data-initial-day]'
      );

      if (isDomNodeType(initialDayNode)) {
        setTimeout(() => {
          initialDayNode.focus();
        }, 0);
      }
    }
  }, [open]);

  // control handlers
  const onPrevMonth = () => setSelectedMonth(lastMonth);
  const onNextMonth = () => setSelectedMonth(nextMonth);
  const onDayCellSelect = (targetDate = null, updateMonthBy = 0) => {
    if (updateMonthBy === -1) onPrevMonth();
    if (updateMonthBy === 1) onNextMonth();
    if (targetDate) {
      setSelectedDay(targetDate);
      // UX requirement to close after date selection
      setOpen(false);
      isDomNodeType(triggerRef?.current) && triggerRef.current.focus();
    }
  };

  // keyboard handler
  const onMiniCalendarKeyDown = useCallback(
    (e) => {
      // keyboard dismissal
      if (
        e.key === 'Escape' &&
        isDomNodeType(triggerRef?.current) &&
        isFunction(setOpen)
      ) {
        setOpen(false);
        triggerRef.current.focus();
      }
    },
    [setOpen, triggerRef?.current]
  );

  useEffect(() => {
    if (open) {
      // Notes:
      // use 'capture' phase here to avoid interference
      // from 'target' and 'bubbling' phase listeners
      document.addEventListener('keydown', onMiniCalendarKeyDown, true);
      return () => {
        document.removeEventListener('keydown', onMiniCalendarKeyDown, true);
      };
    }
  }, [open, onMiniCalendarKeyDown]);

  // auto dismiss
  const handleDismiss = useCallback(() => {
    if (open && isFunction(setOpen)) {
      setOpen(false);
    }
  }, [open, setOpen]);

  useEffect(() => {
    window.addEventListener('popstate', handleDismiss);
    return () => {
      window.removeEventListener('popstate', handleDismiss);
    };
  }, [handleDismiss]);

  // day cells grid keyboard nav
  useKeyNavGrid({
    rootRef: containerRef,
    cellSelector: 'button[data-variant="DAY"]',
    colCount: 7,
    isDisabled: !open,
  });

  // hover days
  const [hoverDay, setHoverDay] = useState(null);
  const onDayCellMouseLeave = () => setHoverDay(null);

  // size
  const [miniCalHeight, setMiniCalHeight] = useState(
    `${MINI_CALENDAR_DEFAULT_HEIGHT}px`
  );

  useMutationObserver(
    () => {
      setMiniCalHeight(
        `${
          containerRef.current?.scrollHeight || MINI_CALENDAR_DEFAULT_HEIGHT
        }px`
      );
    },
    containerRef,
    { childList: true, subtree: true }
  );

  // render
  return (
    <SimpleFocusTrap
      focusableSelectors="button[data-variant='NAV'], button[data-variant='DAY']:not([tabindex='-1'])"
      trapIsOn={open}
    >
      <style>{`:root {--mini-cal-height: ${miniCalHeight}}`}</style>
      <Transition show={open} enterClass={c.enter} exitClass={c.exit}>
        <div className={c.visibilityWrapper}>
          <div
            className={c.calendarContainer}
            ref={containerRef}
            role="dialog"
            aria-modal="true"
            aria-label="Choose date:"
          >
            {/* Header UI */}
            <Cell
              className={c.headerItem}
              variant={CELL_VARIANTS.NAV}
              onClick={onPrevMonth}
              title={format(lastMonth, 'MMMM yyyy')}
              ariaLabel={format(lastMonth, 'MMMM yyyy')}
            >
              <Icon.ChevronLeft />
            </Cell>
            <Cell
              className={[c.month, c.headerItem].join(' ')}
              variant={CELL_VARIANTS.HEADING}
              notInteractive={true}
            >
              {format(selectedMonth, 'MMM yyyy')}
            </Cell>
            <Cell
              className={c.headerItem}
              variant={CELL_VARIANTS.NAV}
              onClick={onNextMonth}
              title={format(nextMonth, 'MMMM yyyy')}
              ariaLabel={format(nextMonth, 'MMMM yyyy')}
            >
              <Icon.ChevronRight />
            </Cell>
            {/* Day Name */}
            {dayOfWeek.map((day, i) => (
              <Cell
                key={i}
                variant={CELL_VARIANTS.TH}
                today={false}
                notInteractive={true}
              >
                {day}
              </Cell>
            ))}
            {/* Last Month */}
            {Array(prefixDays)
              .fill('')
              .map((v, i) => {
                const dateNum = numberOfDaysLastMonth - prefixDays + i + 1;
                const newDate = new Date(
                  getYear(lastMonth),
                  getMonth(lastMonth),
                  dateNum
                );
                return (
                  <Cell
                    key={dateNum}
                    variant={CELL_VARIANTS.DAY}
                    notCurrentMonth={true}
                    today={isSameDay(newDate, today)}
                    initialDay={false}
                    displayDate={isIncludesSameDay(newDate, selectedDay)}
                    hoveringDate={isIncludesSameDay(newDate, hoverDay)}
                    onClick={() => onDayCellSelect(newDate, -1)}
                    onMouseOver={() => setHoverDay(newDate)}
                    onMouseLeave={onDayCellMouseLeave}
                    tabIndex={-1}
                    title={getDayCellScreenReaderMsg(newDate)}
                    ariaLabel={getDayCellScreenReaderMsg(
                      newDate,
                      null,
                      false,
                      true
                    )}
                  >
                    {dateNum}
                  </Cell>
                );
              })}
            {/* Current Month */}
            {Array(numberOfDays)
              .fill('')
              .map((v, i) => {
                const dateNum = i + 1;
                const newDate = new Date(
                  getYear(selectedMonth),
                  getMonth(selectedMonth),
                  dateNum
                );
                const isInitialDayUponOpen = isSameDay(
                  newDate,
                  initialDayUponOpen
                );
                const newDateEvents = flatSchedule?.[newDate];
                const newDateEventsCount = isNumber(newDateEvents?.length)
                  ? newDateEvents.length
                  : 0;
                return (
                  <Cell
                    key={dateNum}
                    variant={CELL_VARIANTS.DAY}
                    today={isSameDay(newDate, today)}
                    initialDay={isInitialDayUponOpen}
                    displayDate={isIncludesSameDay(newDate, selectedDay)}
                    isSelected={isSameDay(newDate, selectedDay)}
                    hoveringDate={isIncludesSameDay(newDate, hoverDay)}
                    onClick={() => onDayCellSelect(newDate)}
                    onMouseOver={() => setHoverDay(newDate)}
                    onMouseLeave={onDayCellMouseLeave}
                    tabIndex={isInitialDayUponOpen ? 0 : -1}
                    title={getDayCellScreenReaderMsg(
                      newDate,
                      newDateEventsCount,
                      true
                    )}
                    ariaLabel={getDayCellScreenReaderMsg(
                      newDate,
                      newDateEventsCount,
                      true,
                      true
                    )}
                  >
                    {dateNum}
                    {/* date events */}
                    {newDateEventsCount > 0 ? (
                      <div className={c.unitDots}>
                        {Array(Math.min(newDateEventsCount, 4))
                          .fill('')
                          .map((v, i) => {
                            return (
                              <div
                                key={i}
                                className={c.dot}
                                style={{
                                  backgroundColor:
                                    unitColours[
                                      newDateEvents[i]?.data?.unitCode
                                    ],
                                }}
                              />
                            );
                          })}
                        {newDateEventsCount > 6 ? (
                          <div className={c.plus} />
                        ) : null}
                      </div>
                    ) : null}
                  </Cell>
                );
              })}
            {/* Next Month */}
            {Array(suffixDays)
              .fill('')
              .map((v, i) => {
                const dateNum = i + 1;
                const newDate = new Date(
                  getYear(nextMonth),
                  getMonth(nextMonth),
                  dateNum
                );
                return (
                  <Cell
                    key={dateNum}
                    variant={CELL_VARIANTS.DAY}
                    notCurrentMonth={true}
                    today={isSameDay(newDate, today)}
                    initialDay={false}
                    displayDate={isIncludesSameDay(newDate, selectedDay)}
                    hoveringDate={isIncludesSameDay(newDate, hoverDay)}
                    onClick={() => onDayCellSelect(newDate, 1)}
                    onMouseOver={() => setHoverDay(newDate)}
                    onMouseLeave={onDayCellMouseLeave}
                    tabIndex={-1}
                    title={getDayCellScreenReaderMsg(newDate)}
                    ariaLabel={getDayCellScreenReaderMsg(
                      newDate,
                      null,
                      false,
                      true
                    )}
                  >
                    {dateNum}
                  </Cell>
                );
              })}
          </div>
        </div>
      </Transition>
    </SimpleFocusTrap>
  );
};

export default MiniCalendar;
