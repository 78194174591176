import React, { useState, useRef, useEffect, useContext } from 'react';
import debounce from 'lodash.debounce';
import {
  Alert,
  IconButton,
  Icon,
  LoadingIndicator,
  Toggle,
} from '@monash/portal-react';
import { useOnOutsideClick } from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { validateDisplayDays } from '../utils';
import UnitColourMenu from '../unit-colours-menu/UnitColourMenu';
import SimpleFocusTrap from 'components/ui/simple-focus-trap/SimpleFocusTrap';
import c from './schedule-settings-menu.module.scss';
import { NO_UNIT_COLOURS_ERROR } from 'constants/error-messages';

const ScheduleSettingsDesktop = (props) => {
  const { daysOptions, numberOfDisplayDays, setNumberOfDisplayDays } = props;
  const {
    unitColours,
    unitColoursIndicesError,
    unitColourMappingsError,
    loading,
  } = useContext(DataContext);
  const hasSomeUnitColourError =
    unitColoursIndicesError || unitColourMappingsError;

  const triggerRef = useRef();
  const menuRef = useRef();
  const btnCloseRef = useRef();

  const [shown, setShown] = useState(false);
  const [containsFocus, setContainsFocus] = useState(false);
  const [menuMaxHeight, setMenuMaxHeight] = useState();

  // restrict max height of the menu to stay within viewport
  useEffect(() => {
    const updateMaxHeight = debounce(() => {
      const menuTop = triggerRef.current?.getBoundingClientRect().bottom; // the top of the menu should line up with the bottom of the settings button
      const bottomMargin = 20; // in px
      const maxHeight = window.innerHeight - menuTop - bottomMargin;
      setMenuMaxHeight(maxHeight);
    }, 100);
    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, [shown]);

  const openMenu = () => {
    setShown(true);
    setContainsFocus(true);
  };

  const closeMenu = (shouldFocusOnTrigger = false) => {
    setShown(false);
    setContainsFocus(false);
    shouldFocusOnTrigger && triggerRef.current?.focus();
  };

  useOnOutsideClick({ refs: [triggerRef, menuRef], fn: closeMenu });

  // init focus on close button when menu is opened
  useEffect(() => {
    if (shown) {
      btnCloseRef.current?.focus();
    }
  }, [shown]);

  const handleKeyDown = (e) => {
    if (e.code === 'Escape' && containsFocus) {
      closeMenu(true);
    }
  };

  // auto dismissal on history nav
  useEffect(() => {
    const onPopState = () => {
      closeMenu();
    };
    if (shown) {
      window.addEventListener('popstate', onPopState);
    }
    // detach event handler regardless if it was attached
    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', onPopState);
      }, 0);
    };
  }, [shown, closeMenu]);

  const headingText = 'Settings';

  const renderMenu = () => {
    return (
      shown && (
        <SimpleFocusTrap trapIsOn={shown}>
          <div
            ref={menuRef}
            className={c.desktopSettingsContainer}
            onKeyDown={handleKeyDown}
            role="dialog"
            aria-label={headingText}
          >
            <div
              className={c.wrapper}
              style={{ maxHeight: `${menuMaxHeight}px` }}
            >
              <div className={c.header}>
                <h2 className={c.heading}>{headingText}</h2>
                <IconButton
                  ref={btnCloseRef}
                  icon={Icon.X}
                  aria-label="Close"
                  className={c.closeButton}
                  onClick={() => closeMenu(true)}
                  color="var(--card-text-color)"
                />
              </div>
              <div className={c.content}>
                {/* days */}
                <div className={c.sidePadded}>
                  <h3 className={c.subheading} tabIndex={-1}>
                    Days
                  </h3>
                  <Toggle
                    options={daysOptions}
                    selected={daysOptions.find(
                      (opt) => opt.data === numberOfDisplayDays
                    )}
                    setSelected={(dayOption) =>
                      setNumberOfDisplayDays(
                        validateDisplayDays(dayOption.data)
                      )
                    }
                    ariaLabel="Days"
                    fullWidth
                    mode="card"
                    data-tracking-event="schedule-view-days"
                  />
                </div>
                <div>
                  <h3
                    className={`${c.sidePadded} ${c.subheading}`}
                    tabIndex={-1}
                  >
                    Edit unit colour
                  </h3>
                  <ul
                    className={c.unitsList}
                    role="menu"
                    aria-label="Edit unit colour"
                  >
                    {loading.unitColours ? (
                      <div className={c.loading}>
                        <LoadingIndicator />
                      </div>
                    ) : hasSomeUnitColourError ? (
                      <div className={c.sidePadded}>
                        <Alert type="error">{NO_UNIT_COLOURS_ERROR}</Alert>
                      </div>
                    ) : (
                      Object.entries(unitColours).map(
                        ([unitCode, unitColour], i) => (
                          <li key={`unit-${unitCode}`} role="menuitem">
                            <UnitColourMenu
                              index={i + 1}
                              unitCode={unitCode}
                              unitColour={unitColour}
                              setParentContainsFocus={setContainsFocus}
                            />
                          </li>
                        )
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SimpleFocusTrap>
      )
    );
  };

  return (
    <div>
      {/* Settings cog button that triggers menu expansion */}
      <IconButton
        ref={triggerRef}
        onClick={() => (shown ? closeMenu() : openMenu())}
        icon={Icon.Setting}
        className={c.settingsButton}
        mode="canvas"
        aria-label="Settings"
        aria-haspopup="dialog"
        aria-expanded={shown ? 'true' : 'false'}
        data-tracking-event="schedule-setting"
      />

      {/* Settings menu */}
      {renderMenu()}
    </div>
  );
};

export default ScheduleSettingsDesktop;
