import Stack from 'components/ui/stack/Stack';
import ErrorScreen from './ErrorScreen';
import { InlineExternalLink } from '@monash/portal-react';
import { getEnvironmentValue } from '@monash/portal-frontend-common';

const GenericError = ({ children }) => {
  const reportBugUrl = getEnvironmentValue({
    prod: 'https://home.student.monash/feedback',
    qa: 'https://home.student-qa.monash/feedback',
    dev: 'https://home.student-qa.monash/feedback',
  });

  const body = children || (
    <>
      <p>
        The app has encountered an unexpected issue. We apologise for the
        inconvenience.{' '}
      </p>
      <p>
        Please try again later. If the issue persists,{' '}
        <InlineExternalLink
          text="report the bug here"
          mode="canvas"
          link={reportBugUrl}
        />
      </p>

      <p>Thank you for your understanding.</p>
    </>
  );

  return (
    <ErrorScreen
      illustration="brokenFlowers"
      heading="Something went wrong"
      message={
        <Stack gap="2.5rem" alignItems="center">
          <Stack gap="1rem" alignItems="center">
            {body}
          </Stack>
        </Stack>
      }
    />
  );
};

export default GenericError;
