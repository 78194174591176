import c from './unit-colour-menu.module.scss';
import {
  useRef,
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useOnOutsideClick } from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { COLOUR_NAMES } from './constants';
import UnitColourMenuContent from './UnitColourMenuContent';
import UnitColourMenuTrigger from './UnitColourMenuTrigger';

const UnitColourMenu = ({ unitCode, unitColour, setParentContainsFocus }) => {
  const { unitColourMappings } = useContext(DataContext);

  const triggerRef = useRef();
  const menuRef = useRef();

  const [shown, setShown] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);

  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
  });

  const focusableSelectors = 'button';
  const focusables = useRef(null);

  // Update focused element
  useEffect(() => {
    if (focusIndex !== null) {
      focusables.current[focusIndex]?.focus();
    }
  }, [focusables, focusIndex]);

  // calculate the menu's fixed position in viewport relative to the trigger button
  useLayoutEffect(() => {
    const triggerRect = triggerRef.current.getBoundingClientRect();
    const menuRect = menuRef.current?.getBoundingClientRect();

    if (!menuRect) {
      return;
    }

    const menuHeight = menuRect.bottom - menuRect.top;
    const menuWidth = menuRect.right - menuRect.left;
    const horizontalOffset = 10;

    let position;

    const overflowsBottom = triggerRect.top + menuHeight > window.innerHeight;

    if (overflowsBottom) {
      // line up:
      // menu's bottom with the trigger's top and
      // menu's right with the trigger's left
      position = {
        top: triggerRect.top - menuHeight,
        left: triggerRect.left - menuWidth + horizontalOffset,
      };
      setMenuPosition(position);
    } else {
      // line up:
      // menu's top with the trigger's top and
      // menu's right with the trigger's left
      position = {
        top: triggerRect.top,
        left: triggerRect.left - menuWidth + horizontalOffset,
      };
      setMenuPosition(position);
    }
  }, [shown]);

  const openMenu = () => {
    setShown(true);
    setParentContainsFocus(false);
  };

  const closeMenu = () => {
    setShown(false);
    setFocusIndex(null);
    setParentContainsFocus(true);
    triggerRef.current.focus();
  };

  useOnOutsideClick({ refs: [menuRef], fn: closeMenu });

  const handleKeyDown = (e) => {
    const first = 0;
    const last = focusables.current.length - 1;

    if (
      // next
      (e.code === 'Tab' && !e.shiftKey) ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowRight'
    ) {
      e.preventDefault();
      // if we reach the end of the menu, wrap back around to the start
      // else, move the focus to the next item
      focusIndex >= last || focusIndex === null
        ? setFocusIndex(first)
        : setFocusIndex((f) => f + 1);
    } else if (
      // prev
      (e.code === 'Tab' && e.shiftKey) ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowLeft'
    ) {
      e.preventDefault();
      // if we reach the start of the menu, wrap back around to the end
      // else, move the focus to the previous item
      focusIndex <= first || focusIndex === null
        ? setFocusIndex(last)
        : setFocusIndex((f) => f - 1);
    } else if (e.code === 'Escape') {
      closeMenu();
    }
  };

  useEffect(() => {
    if (shown) {
      // init focusable elements
      focusables.current =
        menuRef.current?.querySelectorAll(focusableSelectors);

      // init focus on currently selected colour
      const initFocusIndex = Array.from(focusables.current).findIndex(
        (elem) => elem.ariaCurrent === 'true'
      );
      initFocusIndex >= 0 ? setFocusIndex(initFocusIndex) : setFocusIndex(0);
    }
  }, [shown]);

  const unitCodeForScreenReader = unitCode.split('').join(' ');

  return (
    <>
      <UnitColourMenuTrigger
        unitCode={unitCode}
        unitColour={unitColour}
        onClick={openMenu}
        className={c.paddedRow}
        ref={triggerRef}
        aria-haspopup="menu"
        aria-expanded={shown ? 'true' : 'false'}
        aria-label={`Unit code: ${unitCodeForScreenReader}, current colour: ${
          COLOUR_NAMES[unitColourMappings.indexOf(unitColour)]
        }`}
      />

      {/* Popup menu containing the colour palette */}
      {shown && (
        <div
          ref={menuRef}
          className={c.container}
          onKeyDown={handleKeyDown}
          style={menuPosition}
        >
          <UnitColourMenuContent
            unitCode={unitCode}
            unitColour={unitColour}
            className={c.popup}
            postSelectionFunc={closeMenu}
          />
        </div>
      )}
    </>
  );
};

export default UnitColourMenu;
