import { fsCollectionListener } from '@monash/portal-frontend-common';
import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

export const useUpdatesSubscription = ({ onSnapshot, onError }) => {
  useEffect(() => {
    const getUpdates = async () => {
      fsCollectionListener(
        'updates',
        (snapshot) => {
          const updatesFromFirestore = snapshot.docs.map((doc) => doc.data());
          onSnapshot(updatesFromFirestore);
        },
        (error) => {
          Sentry.captureException(error);
          onError(true);
        }
      );
    };
    getUpdates();
  }, []);
};
