import { COURSE_STATUS } from '@monash/portal-frontend-common';
import PageContainer from 'components/pages/PageContainer';
import { pageIds } from '../../../../constants/pages';
import c from '../components/error-pages-shared.module.scss';
import { IntermitMessage } from '../components/messages/IntermitMessage';
import { CompletedMessage } from '../components/messages/CompletedMessage';
import { NoUpcomingEvents } from '../components/messages/NoUpcomingEvents';
import { InactiveMessage } from '../components/messages/InactiveMessage';

export const getMessage = ({
  academicResults,
  courseStatus,
  bannerShown,
  upcomingEvents,
  numMonthsApart,
  userCourses,
}) => {
  const { UPCOMING } = pageIds;

  const message = (() => {
    const hasUpcomingEvents = upcomingEvents.length && numMonthsApart < 3;
    const hasAcademicResultsCourses = Boolean(academicResults?.courses?.length);
    const hasUserCourses = Boolean(userCourses?.length);

    if (!hasAcademicResultsCourses && !hasUserCourses && !hasUpcomingEvents) {
      return <InactiveMessage />;
    }

    if (courseStatus === COURSE_STATUS.INTERMIT) {
      return <IntermitMessage />;
    }

    if (courseStatus === COURSE_STATUS.COMPLETED) {
      return <CompletedMessage />;
    }

    if (courseStatus === COURSE_STATUS.INACTIVE && !hasUpcomingEvents) {
      return <NoUpcomingEvents />;
    }

    if (hasUpcomingEvents) {
      return null;
    }

    return <NoUpcomingEvents />;
  })();

  if (message) {
    return (
      <div className={c.CourseStatusHelp} data-banner-shown={bannerShown}>
        <PageContainer pageId={UPCOMING}>
          <div
            className={c.wrapper}
            aria-label="Upcoming Events board"
            role="region"
          >
            <div className={c.content}>{message}</div>
          </div>
        </PageContainer>
      </div>
    );
  }

  return null;
};
