import * as Sentry from '@sentry/react';

const initMonitoring = (env) => {
  // SENTRY
  Sentry.init({
    dsn: 'https://1c2e617d6f70b82abe1a1385290df61b@o4506034322079744.ingest.sentry.io/4506794305126400',
    environment: env,
    release: process.env.REACT_APP_GIT_SHA,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
        maskAllInputs: true,
      }),
    ],
    ignoreErrors: [
      'Firebase',
      'Firestore',
      '/user/preferences/avatar',
      'Load failed',
      "Can't find variable: CampusM",
    ],
    sampleRate: env === 'prod' ? 0.5 : 1, // Error sample rate
    tracesSampleRate: env === 'prod' ? 0.1 : 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
};

export default initMonitoring;
