import { Icon, RouterState, Collapsible, Badge } from '@monash/portal-react';
import React, { useContext, useEffect, useRef } from 'react';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import c from './page-dropdown.module.scss';
import PageSettingsMenu from '../../page-nav/page-settings-menu/PageSettingsMenu';
import { useOnOutsideClick } from '@monash/portal-frontend-common';
import { getRouteFromPageIndex } from 'components/utilities/pages';
import { UpdatesContext } from 'components/providers/updates-provider/UpdatesProvider';
import { pageIds } from '../../../../../constants';

const PageDropDown = () => {
  const {
    selectedPage,
    pages,
    isExpanded,
    setIsExpanded,
    setIsProgrammaticScrolling,
  } = useContext(PageContext);
  const { redirect } = useContext(RouterState);
  const { newCount: newUpdatesCount } = useContext(UpdatesContext);

  const pagesWithNewCount = pages?.map((page) => {
    if (page.id === pageIds.UPDATES && newUpdatesCount) {
      return { ...page, newCount: newUpdatesCount };
    }

    return page;
  });

  const totalNewCount = pagesWithNewCount.reduce((acc, curr) => {
    if (curr.newCount) {
      return acc + curr.newCount;
    }
    return acc;
  }, 0);

  const handleOnClick = () => {
    setIsExpanded(!isExpanded);
  };

  const dropDownListRef = useRef();
  const triggerRef = useRef();

  function scrollViaPopState(event) {
    const path = event.state.page;
    const index = pages.findIndex((page) => page.path === path);
    scrollToSection(index);
  }

  useEffect(() => {
    window.addEventListener('popstate', scrollViaPopState);

    return () => window.removeEventListener('popstate', scrollViaPopState);
  }, []);

  const scrollToSection = (i) => {
    // Redirect the routing when click
    redirect(getRouteFromPageIndex(pages, i));
    const pageElements = document.querySelectorAll('[data-page]');
    const pageElement = pageElements[i];

    const scrollHeight = pageElement?.offsetTop;
    // Scroll to the selected section
    setIsProgrammaticScrolling(true);

    // Make sure the page land on the current position with or without the top nav bar
    const scrollOffset = document.getElementById('topNavContainer')?.offsetTop
      ? -20
      : -80;

    document.getElementById('VerticalMain')?.scrollTo({
      top: scrollHeight + scrollOffset,
    });

    // Close dropdown
    setIsExpanded(false);
  };

  useOnOutsideClick({
    refs: [triggerRef, dropDownListRef],
    fn: () => {
      setIsExpanded(false);
    },
  });

  const renderMenuItems = pagesWithNewCount?.map((item, i) => {
    return (
      <li key={`p${i + 1}-${item.name}`}>
        <button
          type="button"
          className={[c.menuItem, selectedPage === i && c.selected].join(' ')}
          onClick={() => scrollToSection(i)}
          data-tracking-event={
            'topnav-mobile-page-dropdown-item-' + `${item.name.toLowerCase()}`
          }
        >
          <span className={c.menuTextWrapper}>
            {item.name.toUpperCase()}

            {Boolean(item.newCount) && (
              <Badge
                status="information"
                count={item.newCount}
                offset={[-2, 3]}
              />
            )}
          </span>
        </button>
      </li>
    );
  });

  return (
    <div className={c.mobileNavContainer} ref={dropDownListRef}>
      <button
        type="button"
        className={c.headerContainer}
        onClick={() => handleOnClick()}
        data-tracking-event={
          'topnav-mobile-page-dropdown-' +
          `${pages[selectedPage]?.name.toLowerCase()}`
        }
        ref={triggerRef}
      >
        <div className={c.headerTextWrapper}>
          {Boolean(totalNewCount) && !isExpanded && (
            <Badge
              status="information"
              count={totalNewCount}
              offset={[-4, 3]}
            />
          )}
          <div className={c.headerText}>{pages[selectedPage]?.name}</div>
        </div>

        <div
          className={c.collapsibleViewToggle}
          data-hide={!isExpanded ? 'true' : 'false'}
        >
          <Icon.ChevronDown color="var(--canvas-text-color)" />
        </div>
      </button>

      <div className={c.pageSetting} data-hide={!isExpanded ? 'true' : 'false'}>
        <PageSettingsMenu />
      </div>

      <Collapsible
        expanded={isExpanded}
        contentOffset={40}
        usecustomstyles={c.navItemsContainer}
      >
        <div className="childrenContent">
          <ul className={c.listContainer}>{renderMenuItems}</ul>
        </div>
      </Collapsible>
    </div>
  );
};

export default PageDropDown;
