import { useRef } from 'react';
import { useTabFocus, Card } from '@monash/portal-react';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import Accent from 'components/ui/accent/Accent';
import ListingHeading from './ListingHeading';
import getWorkshopText from 'components/utilities/user-events/get-workshop-text';
import { getLocation } from 'components/utilities/user-events/get-location';
import {
  eventsOnDay,
  getListingTime,
  getTitle,
  formatEventTitleAbbr,
} from '../utils/utils';
import TimeIndicator from './TimeIndicator/TimeIndicator';
import {
  getTimeIndicatorData,
  getTimeIndicatorStyle,
} from './TimeIndicator/utils';
import {
  getDisplayStatus,
  getDisplayStatusComponent,
} from 'components/utilities/user-events/render-status';
import c from './upcoming-listing-desktop.module.scss';
import cs from './upcoming-listing-shared.module.scss';
import { getDisplayDate } from 'components/utilities/user-events/get-display-date';
import { isSameDay, isValid } from 'date-fns';
import WarningBox from 'components/ui/WarningBox/WarningBox';
import { getUserEventsErrorMessage } from 'components/providers/data-provider/utils/getUserEventsError';
import AssessmentsWarning from 'components/ui/AssessmentsWarning/AssessmentsWarning';

const UpcomingListingDesktop = ({
  currentDate,
  events,
  setActiveIndex,
  activeIndex,
  showLocation,
  setShowLocation,
  userEventsError,
}) => {
  const cardRefs = useRef([]);
  const eventsOnToday = eventsOnDay(events, currentDate);
  const userEventsErrorMessage = getUserEventsErrorMessage(userEventsError);

  const { moveFocus } = useTabFocus({
    tabRefs: cardRefs,
    onChange: setActiveIndex,
    tabCount: eventsOnToday ? events.length : events.length + 1, // add 1 to account for additional "No events" card if there are no events today
  });
  const timeIndicatorData = getTimeIndicatorData({
    events,
    currentDate,
    includeNextEvent: true,
  });

  const primaryHeadingId = 'upcoming-listing-heading-primary';
  const secondaryHeadingId = 'upcoming-listing-heading-secondary';

  const getEventCards = () => {
    return events
      .sort((a, b) => a.start.time - b.start.time)
      .map((event, i) => {
        // if user has no events today, add offset of 1 to the index to accommodate for the "No events" card
        const index = eventsOnToday ? i : i + 1;

        const isMultiDayEvent =
          isValid(event.start?.time) &&
          isValid(event.end?.time) &&
          !isSameDay(event.start?.time, event.end?.time);

        const time = isMultiDayEvent
          ? event.display?.showTime('collapsedMultiday')
          : getListingTime(event);

        const location = getLocation(event.location);
        const workshopText = getWorkshopText(event.eventType);
        const eventTitleText =
          capitaliseFirstWord(event.data?.unitTitle || event.data?.name) ||
          null;
        const eventTitle = getTitle(event);
        const eventTitleFormatted = formatEventTitleAbbr(eventTitle, {
          title: eventTitleText,
          'aria-label': getTitle(event, true),
        });
        const isSelected = activeIndex === index;
        const displayStatus = getDisplayStatus({
          status: event.data?.status,
          currentDate,
          submissionStartDate: event.data?.submissionStartDate?.time,
        });
        const isAssessment = event.eventKind === 'assessment';

        const timeIndicatorDatum = timeIndicatorData?.find(
          ({ index: indicatorIndex }) => index === indicatorIndex
        );
        const timePercentage = timeIndicatorDatum?.timePercentage;
        const hasTimeIndicator = Boolean(timeIndicatorDatum);
        const isFinished = timeIndicatorDatum?.isFinished;
        const style = getTimeIndicatorStyle({
          timePercentage,
          isFinished,
        });

        return (
          <Card
            key={event.id}
            id={`upcoming-tab-${index}`}
            onClick={() => {
              setActiveIndex(index);
            }}
            selected={isSelected}
            aria-label={`${time}. ${eventTitle}`}
            aria-selected={isSelected}
            aria-controls={`upcoming-tabpanel-${index}`}
            role="tab"
            tabIndex={isSelected ? 0 : -1}
            onKeyDown={moveFocus}
            ref={(e) => (cardRefs.current[index] = e)}
            data-tracking-event="upcoming-event-listing"
          >
            <div aria-hidden="true">
              {eventsOnToday && hasTimeIndicator && (
                <TimeIndicator style={style} />
              )}

              {time && <div className={cs.dueTime}>{time}</div>}

              <div className={cs.classHeaderWrapper}>
                <div className={cs.classHeader}>
                  <Accent unitCode={event.display.unit} />
                  <div className={cs.classHeaderText}>
                    <h3>{eventTitleFormatted}</h3>
                    {workshopText && <div>{workshopText}</div>}
                  </div>
                </div>
                {isAssessment && (
                  <span className={cs.status}>
                    {getDisplayStatusComponent({
                      status: displayStatus,
                      mode: 'card',
                    })}
                  </span>
                )}
              </div>

              {showLocation && location && (
                <div className={cs.location}>{location}</div>
              )}
            </div>
          </Card>
        );
      });
  };

  return (
    <div className={c.upcomingListing}>
      {userEventsErrorMessage && (
        <WarningBox className={cs.warningBox}>
          {userEventsErrorMessage}
        </WarningBox>
      )}

      <ListingHeading
        currentDate={currentDate}
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        headingId={primaryHeadingId}
      />
      {!eventsOnToday ? (
        // If no events are on today, display a section of tabs for today and a section of tabs for the next day with upcoming events within a tablist
        <div role="tablist">
          {/* The section for today will have just one card reading "No events" */}
          <div aria-labelledby={primaryHeadingId}>
            <Card
              ref={(e) => (cardRefs.current[0] = e)}
              id="upcoming-tab-0"
              className={c.noEventsCard}
              onClick={() => {
                setActiveIndex(0);
              }}
              selected={activeIndex === 0}
              aria-selected={activeIndex === 0}
              aria-controls="upcoming-tabpanel-0"
              role="tab"
              tabIndex={activeIndex === 0 ? 0 : -1}
              onKeyDown={moveFocus}
              data-tracking-event="upcoming-no-event-listing"
            >
              <span>No events</span>
            </Card>
          </div>

          {/* Heading for next day with upcoming events */}
          <h2
            className={c.secondaryListingHeading}
            role="presentation"
            id={secondaryHeadingId}
          >
            {getDisplayDate(currentDate, events[0]?.start.time)}
          </h2>

          {/* Section for upcoming events */}
          <div className={cs.eventsList} aria-labelledby={secondaryHeadingId}>
            {getEventCards()}
            <AssessmentsWarning />
          </div>
        </div>
      ) : (
        // If there are events today, display them all in one tablist with no sections
        <div
          role="tablist"
          className={cs.eventsList}
          aria-labelledby={primaryHeadingId}
        >
          {getEventCards()}
          <AssessmentsWarning />
        </div>
      )}
    </div>
  );
};

export default UpcomingListingDesktop;
