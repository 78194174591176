import { Badge, Icon, IconButton } from '@monash/portal-react';
import { ASSESSMENTS_OPTION_KEY } from 'components/pages/custom/widgets/assessments/Assessments';
import React, { useContext, useRef } from 'react';
import { isObject, isStringEmpty } from '@monash/portal-frontend-common';
import { WidgetContext } from 'components/providers/WidgetProvider';
import {
  getOptionFilterText,
  getUnitCodesWithAssessments,
  injectFilterOptionsWithUnits,
} from 'components/pages/custom/widgets/assessments/utils';
import { DataContext } from 'components/providers/data-provider/DataProvider';

const AssessmentsFilterAction = ({
  data,
  widgetId,
  widgetName,
  props, // filterOptions
}) => {
  const { editAssessmentsFilter, assessmentsFilterEscapeRef } =
    useContext(WidgetContext);
  const { userEvents } = useContext(DataContext);
  const buttonRef = useRef();

  const unitCodesWithAssessments = getUnitCodesWithAssessments(userEvents);
  const filterData = data?.[ASSESSMENTS_OPTION_KEY.FILTER];
  const filterOptions = injectFilterOptionsWithUnits(
    props.filterOptions,
    unitCodesWithAssessments
  );

  // get number of filter categories that are active
  // a category is active if any option in the category is toggled to 'off'
  const getNumFiltersActive = (filterData) => {
    if (!isObject(filterData)) return null;

    return Object.keys(filterData).reduce((acc, category) => {
      // specifically looking for false, and not 'falsy' values e.g. undefined
      // b/c we want options to be 'on' by default, so we don't apply filters
      // if option data is undefined (i.e. not found)
      if (
        Object.values(filterData[category]).some((option) => option === false)
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const numFiltersActive = getNumFiltersActive(filterData);

  // aria label describes which filter options are selected
  const ariaLabel = filterOptions.reduce((acc, optionModule, index) => {
    const optionText = getOptionFilterText({
      options: optionModule.options,
      optionData: filterData?.[optionModule.key],
      optionName: optionModule.name,
    });

    // convert text to lowercase except for unit codes
    const unitCodePattern = /[A-Za-z]{3}\d{4}/g;
    const formattedText = isStringEmpty(optionText)
      ? ''
      : optionText.toLowerCase().replace(unitCodePattern, (match) => {
          return match.toUpperCase();
        });

    if (index === 0) return acc + formattedText;

    return acc + ', ' + formattedText;
  }, `${widgetName} widget filtered by `);

  return (
    <span>
      <IconButton
        ref={buttonRef}
        icon={Icon.Filter}
        color="var(--card-text-color)"
        aria-label="Filters"
        onClick={() => {
          editAssessmentsFilter(widgetId);
          assessmentsFilterEscapeRef.current = buttonRef.current;
        }}
        aria-haspopup="dialog"
      />
      {numFiltersActive > 0 && (
        <Badge
          count={numFiltersActive}
          status="information"
          offset={[-8, 10]}
          aria-label={ariaLabel}
          contentAriaHidden="true"
        />
      )}
    </span>
  );
};
export default AssessmentsFilterAction;
