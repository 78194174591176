import React, { useContext, useRef } from 'react';
import { ThemeContext } from 'components/providers/theme-provider/ThemeProvider';
import headingColorsList from './heading-colors-list';
import backgroundsList, { BACKGROUND_TYPE } from './backgrounds-list';
import WallpaperButton from './WallpaperButton';
import cardColoursList from './card-colors-list';
import cardCtaColoursList from './card-cta-colors-list';
import isSameLowerCaseString from 'utils/is-same-lower-case-string';
import useKeyNavGroups, {
  KEY_NAV_MODE,
} from '../../../hooks/use-key-nav-groups';
import { getCustomThemeGroupAriaLabel } from '../utils';
import c from './custom-theme.module.scss';

const CUSTOM_THEME_GROUP_TITLE = {
  HEADING: 'Heading',
  CARD: 'Card',
  PRIMARY: 'Button and accent',
  BACKGROUND: 'Background',
  WALLPAPER: 'Wallpaper',
};
const CUSTOM_THEME_GROUP_COUNT = Object.keys(CUSTOM_THEME_GROUP_TITLE).length;

const CustomTheme = () => {
  const { wallpaperList, customThemeVariables, updateCustomTheme } =
    useContext(ThemeContext);

  const isSelectedOption = (variable, value) =>
    isSameLowerCaseString(customThemeVariables[variable], value) &&
    customThemeVariables.inUse;
  const selectedClass = (variable, value) =>
    isSelectedOption(variable, value) ? c.selected : null;

  // heading colours
  const headings = headingColorsList.map((item) => (
    <button
      aria-label={'Heading: ' + item.name}
      aria-current={
        isSelectedOption('headingColor', item.value) ? 'true' : null
      }
      key={item.name}
      type="button"
      data-tracking-event={'themer-heading-color-' + item.value}
      style={{ backgroundColor: item.value, borderColor: item.border }}
      className={selectedClass('headingColor', item.value)}
      onClick={() =>
        updateCustomTheme([{ attribute: 'headingColor', value: item.value }])
      }
    />
  ));

  // card colours
  const cards = cardColoursList.map((item) => (
    <button
      aria-label={'Card: ' + item.name}
      aria-current={isSelectedOption('cardColor', item.value) ? 'true' : null}
      key={item.name}
      type="button"
      data-tracking-event={'themer-card-color-' + item.value}
      style={{ backgroundColor: item.value, borderColor: item.border }}
      className={
        isSelectedOption('cardColor', item.value)
          ? [selectedClass('cardColor', item.value), c.additionalBorder].join(
              ' '
            )
          : null
      }
      onClick={() => {
        updateCustomTheme([
          { attribute: 'cardColor', value: item.value },
          { attribute: 'textColor', value: item.contrast },
        ]);
      }}
    />
  ));

  // card cta colours
  const cardCta = cardCtaColoursList.map((item) => (
    <button
      aria-label={'Accent: ' + item.name}
      aria-current={
        isSelectedOption('cardCtaColor', item.value) ? 'true' : null
      }
      key={item.name}
      type="button"
      data-tracking-event={'themer-card-cta-color-' + item.value}
      style={{ backgroundColor: item.value, borderColor: item.border }}
      className={selectedClass('cardCtaColor', item.value)}
      onClick={() => {
        updateCustomTheme([{ attribute: 'cardCtaColor', value: item.value }]);
      }}
    />
  ));

  // background colours
  const backgrounds = backgroundsList.map((item, i) => (
    <button
      key={i}
      aria-label={`Background${
        item.type === BACKGROUND_TYPE.COLOUR ? ' colour' : ''
      }: ${item.name}`}
      aria-current={isSelectedOption('background', item.value) ? 'true' : null}
      type="button"
      onClick={() =>
        updateCustomTheme([
          { attribute: 'background', value: `${item.value}` },
          { attribute: 'canvasCtaTextColor', value: `${item.text}` },
        ])
      }
      className={selectedClass('background', item.value)}
      data-tracking-event={'themer-background-color-' + item.value}
    >
      <div
        className={c.previewBlock}
        style={{
          background: item.value,
        }}
      />
    </button>
  ));

  // wallpaper
  const wallpaper = wallpaperList.map((url, i) => (
    <WallpaperButton url={url} key={url} number={i} />
  ));

  // AX keyboard nav group enhancement
  const customThemeContainerRef = useRef(null);
  useKeyNavGroups({
    rootRef: customThemeContainerRef,
    groupSelector: '[data-key-nav-group^="custom-theme-row"]',
    keyNavMode: KEY_NAV_MODE.BOTH,
  });

  // render
  return (
    <div className={c.customTheme} ref={customThemeContainerRef}>
      {/* heading colours */}
      <div
        tabIndex={0}
        className={c.card}
        data-key-nav-group="custom-theme-row-heading"
        aria-label={getCustomThemeGroupAriaLabel(
          CUSTOM_THEME_GROUP_TITLE.HEADING,
          1,
          CUSTOM_THEME_GROUP_COUNT
        )}
      >
        <span>{CUSTOM_THEME_GROUP_TITLE.HEADING}</span>
        <div className={c.colorSelectors}>{headings}</div>
      </div>

      {/* card colours */}
      <div
        tabIndex={0}
        className={c.card}
        data-key-nav-group="custom-theme-row-card"
        aria-label={getCustomThemeGroupAriaLabel(
          CUSTOM_THEME_GROUP_TITLE.CARD,
          2,
          CUSTOM_THEME_GROUP_COUNT
        )}
      >
        <span>{CUSTOM_THEME_GROUP_TITLE.CARD}</span>
        <div className={c.colorSelectors}>{cards}</div>
      </div>

      {/* primary colours */}
      <div
        tabIndex={0}
        className={c.card}
        data-key-nav-group="custom-theme-row-primary"
        aria-label={getCustomThemeGroupAriaLabel(
          CUSTOM_THEME_GROUP_TITLE.PRIMARY,
          3,
          CUSTOM_THEME_GROUP_COUNT
        )}
      >
        <span>{CUSTOM_THEME_GROUP_TITLE.PRIMARY}</span>
        <div className={c.colorSelectors}>{cardCta}</div>
      </div>

      {/* background colours */}
      <div
        tabIndex={0}
        className={c.background}
        data-key-nav-group="custom-theme-row-background"
        aria-label={getCustomThemeGroupAriaLabel(
          CUSTOM_THEME_GROUP_TITLE.BACKGROUND,
          4,
          CUSTOM_THEME_GROUP_COUNT
        )}
      >
        <span>{CUSTOM_THEME_GROUP_TITLE.BACKGROUND}</span>
        <div className={c.backgroundSelectors}>{backgrounds}</div>
      </div>

      {/* wallpaper */}
      <div
        tabIndex={0}
        className={c.background}
        data-key-nav-group="custom-theme-row-wallpaper"
        aria-label={getCustomThemeGroupAriaLabel(
          CUSTOM_THEME_GROUP_TITLE.WALLPAPER,
          5,
          CUSTOM_THEME_GROUP_COUNT
        )}
      >
        <span>{CUSTOM_THEME_GROUP_TITLE.WALLPAPER}</span>
        <div className={c.backgroundSelectors}>{wallpaper}</div>
      </div>
    </div>
  );
};

export default CustomTheme;
