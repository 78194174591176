import React, { useContext } from 'react';
import c from './custom-theme.module.scss';
import { ThemeContext } from 'components/providers/theme-provider/ThemeProvider';
import wallpaperTextColorsList from './wallpaper-text-colors-list';

const WallpaperButton = ({ url, number }) => {
  const { updateCustomTheme, customThemeVariables } = useContext(ThemeContext);
  const isSelected = customThemeVariables.background === `url('${url}')`;
  return (
    url && (
      <button
        type="button"
        aria-label={`Wallpaper: ${number + 1}`}
        aria-current={isSelected ? 'true' : null}
        className={[c.WallpaperButton, isSelected ? c.selected : null].join(
          ' '
        )}
        onClick={() =>
          updateCustomTheme([
            { attribute: 'background', value: `url('${url}')` },
            {
              attribute: 'canvasCtaTextColor',
              value: `${
                wallpaperTextColorsList[number]
                  ? wallpaperTextColorsList[number]
                  : '#333'
              }`,
            },
          ])
        }
      >
        <img
          className="custom-theme"
          src={url}
          alt={`Wallpaper from ${url}`}
          data-tracking-event={'themer-wallpaper-' + number + 1}
        />
      </button>
    )
  );
};

export default WallpaperButton;
