import React, { useContext, useRef, useState } from 'react';
import c from './page-heading.module.scss';
import PageNameInput from './page-name-input/PageNameInput';
import {
  formatNewPageName,
  transformPageNameForURL,
  validateNewPageName,
} from 'components/utilities/pages';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import ErrorMessage from './error-message/ErrorMessage';
import debounce from 'lodash.debounce';
import {
  ImpersonationContext,
  fsUpdateDoc,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { LoadingIndicator, RouterState } from '@monash/portal-react';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { useEffectIgnoringMount } from 'hooks/use-effect-ignoring-mount';
const VALIDATION_DEBOUNCE = 200;

const PageHeading = ({ pageId, pageName, isRenaming, setIsRenaming }) => {
  const { allPages } = useContext(PageContext);
  const { setPortalPreferences } = useContext(DataContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { redirect } = useContext(RouterState);
  const headingRef = useRef();
  const { addSnackbar } = useSnackbar();
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);

  // rename
  const [pageNameInput, setPageNameInput] = useState('');
  const [hasTypingStarted, setHasTypingStarted] = useState(false);
  const [validation, setValidation] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // reset
  const resetInput = () => {
    setPageNameInput('');
    setIsRenaming(false);
    setHasTypingStarted(false);
    setValidation(null);
  };

  // start

  // on key press (enter)
  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setIsRenaming(true);
    }
  };

  // during

  // validation
  const validateInput = debounce(
    (input) => setValidation(validateNewPageName(allPages, pageName, input)),
    VALIDATION_DEBOUNCE
  );

  // on change
  const handleOnChange = (e) => {
    setHasTypingStarted(true);
    setPageNameInput(e.target.value);
    validateInput(e.target.value);
  };

  // end

  // update
  const updatePageName = () => {
    setIsUpdating(true);
    const newName = formatNewPageName(pageNameInput);
    const id = currentUser.uid;
    const path = `users/${id}`;
    fsUpdateDoc(path, {
      [`preferences.pages.customPages.${pageId}.name`]: newName,
    })
      .then(() => handleUpdateSuccess(newName))
      .catch(handleUpdateError)
      .finally(() => setIsUpdating(false));
  };

  const handleUpdateSuccess = (newName) => {
    setPortalPreferences((f) => {
      const preferences = { ...f };
      preferences.pages.customPages[pageId].name = newName;
      return preferences;
    });
    redirect(`/page/${transformPageNameForURL(newName)}`);
    resetInput();
  };

  const handleUpdateError = (error) => {
    resetInput();
    resetAppLiveMsgs();
    addSnackbar({
      message: "We can't save the page name right now - please try again later",
      type: 'error',
    });
    console.warn(
      '[updatePortalPreferences]: api call error, failed to rename page',
      error
    );
  };

  // on blur and on key press (enter or tab)
  const finishRenaming = () => {
    // early exit
    if (!hasTypingStarted) {
      resetInput();
      return;
    }

    // validate new page at the end to fight race condition
    const finalValidation = validateNewPageName(
      allPages,
      pageName,
      pageNameInput
    );

    if (finalValidation?.valid) {
      updatePageName();
    }
  };

  // focus on heading
  useEffectIgnoringMount(() => {
    if (!isRenaming) {
      headingRef.current?.focus();
    }
  }, [isRenaming]);

  return (
    <>
      {isRenaming && !isUpdating && validation?.valid === false ? (
        <ErrorMessage errorMessage={validation?.errorMessage} />
      ) : null}
      <div className={c.pageHeading}>
        {isRenaming && !isUpdating && (
          <PageNameInput
            resetInput={resetInput}
            finishRenaming={finishRenaming}
            pageName={pageName}
            pageNameInput={pageNameInput}
            setPageNameInput={setPageNameInput}
            onChange={handleOnChange}
          />
        )}
        {!isRenaming && !isUpdating && (
          <div
            className={c.heading}
            ref={headingRef}
            tabIndex={0}
            onClick={() => setIsRenaming(true)}
            onKeyDown={handleOnKeyDown}
            data-tracking-event="custom-page-heading"
            aria-label={`${pageName}. Press enter to customise your page name.`}
          >
            {pageName}
          </div>
        )}
        {isUpdating ? (
          <LoadingIndicator color="var(--canvas-text-color)" />
        ) : null}
      </div>
    </>
  );
};

export default PageHeading;
