import React, { useContext } from 'react';
import c from './theme-selector.module.scss';
import { themes } from '@monash/portal-react';
import { ThemeContext } from 'components/providers/theme-provider/ThemeProvider';

const ThemeSelector = ({ theme }) => {
  const { selectTheme, selectedTheme, customThemeVariables } =
    useContext(ThemeContext);
  const themeVariables = themes[theme].variables;

  const handleClick = () => {
    selectTheme(theme);
  };

  const isThemeSelected =
    customThemeVariables.inUse === false && theme === selectedTheme;
  return (
    <button
      aria-label={theme}
      aria-current={isThemeSelected ? 'true' : null}
      className={[c.themeSelector, isThemeSelected ? c.selected : null].join(
        ' '
      )}
      onClick={handleClick}
      type="button"
      data-tracking-event={'themer-gallery-' + `${theme}`}
    >
      <div
        className={c.wrapper}
        style={{
          background: themeVariables['--canvas-bg-color'],
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className={c.sampleUI}>
          <div
            className={c.text}
            style={{
              backgroundColor: themeVariables['--canvas-text-color'],
            }}
          />
          <div
            className={c.card}
            style={{
              backgroundColor: themeVariables['--card-bg-color'],
              backgroundImage: themeVariables['--card-bg-image'],
            }}
          />
        </div>
      </div>
    </button>
  );
};

export default ThemeSelector;
